import React from 'react';
import { Box, Card, CardContent, Divider, IconButton, Stack, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ImageInterestPoint from '~/features/InterestPoint/Components/ImageInterestPoint';
import Close from '@mui/icons-material/Close';
import { useLang } from '~/hooks/useLang';

export default function MarkerPopupContentDevice({ isCriminal, typeName, subtypeName, expirationDate, revisionDate, validatedAt, location, nameInterestPoint, medias, closeThisPopUp, notes }) {
  const { translate } = useLang()
  const verifyExistMedia = medias.map((item) => item)

  return (
    <Card sx={{ width: '100%', backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none'}}>
      <CardContent sx={{ padding: '1em 0 1em 0' }}>
        <Stack direction={'row'} textAlign={'center'} sx={{ margin: '.6em 0' }} justifyContent={'space-between'}>
          <Typography variant="subtitle1">
            <b>{nameInterestPoint}</b>
          </Typography>
          <Box>
          <IconButton size="small" onClick={() => closeThisPopUp()}>
            <Close fontSize="small" />
          </IconButton>
          </Box>
        </Stack>

        <Stack direction='column' alignItems='flex-start' sx={{ margin: '.6em 0' }} textAlign={'left'}>
          <Typography variant="subtitle1" >
            {`${typeName ? typeName : ''}`} {subtypeName ? ` / ${subtypeName}` : ''}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='flex-start' width={'100%'} textAlign={'left'} sx={{ margin: '.6em 0' }}>
          <Typography variant="subtitle1" >
            {location}
          </Typography>
        </Stack>

        <Divider />
        <Stack direction='row' alignItems='flex-start' width={'100%'} textAlign={'left'} sx={{ margin: '.6em 0', overflowY: 'scroll', maxHeight: '350px' }}>
          <Typography
            variant="subtitle1" 
            style={{
              wordWrap: 'break-word',
              marginBottom: '0',
              whiteSpace: 'pre-line',
            }}
            >
            {notes ? notes : `${translate('There are no observations')}`}
          </Typography>
        </Stack>

        <Divider />
        {verifyExistMedia.length > 0 &&
          <Carousel
            NextIcon={<KeyboardArrowRightIcon />}
            PrevIcon={<KeyboardArrowLeftIcon />}
            height={'200px'}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            autoPlay={false}
            animation={'slide'}
            navButtonsAlwaysInvisible={verifyExistMedia.length <= 1 ? true : false}
            duration={600}
          >
            {medias.map((element) => (
              element.media_path ? <ImageInterestPoint key={element.id} images={JSON.parse(JSON.stringify(element))} /> : false
            ))}
          </Carousel>
        }

      </CardContent>
    </Card>
  )
}