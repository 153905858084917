import { AxiosResponse } from "axios";
import { authFetch } from "~/services/fetch";

type fetchCancelFunctionReturn = {
    readonly promise: Promise<AxiosResponse<string>>;
    readonly cancelToken: AbortController;
} 

export function getLogoBase64(darkMode): fetchCancelFunctionReturn {
    const cancelToken = new AbortController();

    const base64Promise = authFetch({
        url: '/service/get-logo',
        method: 'POST',
        signal: cancelToken.signal,
        data: {
            dark_mode: darkMode
        }
    })

    return {
        promise: base64Promise,
        cancelToken
    }
}
// export function postLogo(file): fetchCancelFunctionReturn {
//     const cancelToken = new AbortController();

//     const base64Promise = authFetch({
//         url: '/service/logo',
//         method: 'POST',
//         headers: 
//         signal: cancelToken.signal,
//         data: {
//             file: file
//         }
//     })

//     return {
//         promise: base64Promise,
//         cancelToken
//     }
// }