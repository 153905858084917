import React, { useEffect, useMemo } from 'react';
import { State, createState, useHookstate, useState } from '@hookstate/core';
import ISystem from '../interfaces/ISystem';
import IStatusColors from '../interfaces/IStatusColors';
import IPriorityColors from '../interfaces/IPriorityColors';
import IEntryStatusColors from '../interfaces/IEntryStatusColors';
import ISystemState from '../interfaces/ISystemState';
import IWindowManager from '../interfaces/IWindowManager';
import ISystemConfig from '../interfaces/ISystemConfig';
import ISystemConfigState from '../interfaces/ISystemConfigState';
import TabListVehicle from '~/components/TabListVehicle';
import { configTheme } from '~/components/Theme';
import { useTheme } from '../../../hooks/useTheme';
import EntryFormModalDraggable from '~/features/Entry/components/EntryFormModalDraggable';
import OccurrenceListModalDraggable from '~/features/Occurrence/components/OccurrenceListModalDraggable';
import DispatchFormModalDraggable from '~/features/Dispatch/components/DispatchFormModalDraggable';
import OccurrenceModalDraggable from '~/features/Occurrence/components/OccurrenceModalDraggable';
import SearchVehicleModalDraggable from '~/features/Vehicle/components/SearchVehicleModalDraggable'
import SearchInvolvementModalDraggable from '~/features/User/components/SearchInvolvementModalDraggable';
import MapSettingModalDraggable from '~/features/Map/components/MapSettingModalDraggable';
import TabListDispatchesSocket, { TabListDispatchesSocketProps } from '~/components/ListDispatchesSocket';
import TabListVehiclesSocket from '~/components/ListVehiclesSocket';
import { Broadcasted } from '@hookstate/broadcasted';
import { getSystemConfigService, getUserSettings, storeScreenSettings } from '~/features/System/services';
import { toCamelCase } from '~/utils/strings';
import VideoMonitoringModalDraggable from '~/features/VideoMonitoring/components/VideoMonitoringModalDraggable';
import dayjs from 'dayjs';
import SearchStaffModalDraggable from '~/features/Vehicle/components/SearchStaffModalDraggable';
import EntryRecallModalDraggable from '~/features/Entry/components/EntryRecallModalDraggable';
import EntryLogModal from '~/features/Entry/components/EntryLogModal';
import EntryLogModalDraggable from '~/features/Entry/components/EntryLogModal';
import VehicleModalDraggable from '~/features/Entry/components/VehicleModalDraggable';
import DispatchUnificationModalDraggable from '~/features/Dispatch/components/DispatchUnificationModalDraggable';
import DispatchDisunificationModalDraggable from '~/features/Dispatch/components/DispatchDisunificationModalDraggable';
import RegistrationModalDraggable from '~/components/RegistrationModal';
import VideoMonitoringCardsDraggable from '~/features/VideoMonitoring/components/VideoMonitoringCardsDraggable';
import MessageModalDraggable from '~/features/Message/components/MessageModalDraggable';
import { title } from 'process';
import EntryProcedureModalDraggable from '~/features/Entry/components/EntryProcedureModalDraggable';
import DispatchProcedureModalDraggable from '~/features/Dispatch/components/DispatchProcedureModalDraggable';
import TabListDispatch from '~/features/Dispatch/components/TabListDispatch';

import DispatchResultSearchesModalDraggable from '~/features/Dispatch/components/DispatchesResulSearchesModalDraggable';
import SearchWeaponModalDraggable from '~/features/Dispatch/components/SearchWeaponModalDraggable';
import MapFiltersModalDraggable from '~/features/Map/components/MapFiltersModalDraggable';
import MapHistoricFiltersModalDraggable from '~/features/Map/components/MapHistoricFiltersModalDraggable';


const windowManagerDefault = createState<IWindowManager>({
  entry: {
    title: `Atendimento`,
    component: <EntryFormModalDraggable />,
    open: window.location.pathname === '/entry' ? true : false,
    disabled: false,
    hide: false,
    readonly: false,
    componentName: 'EntryFormModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,

  },
  dispatch: {
    title: `Despacho`,
    component: <DispatchFormModalDraggable />,
    open: false,
    disabled: false,
    hide: false,
    readonly: false,
    minimized: true,
    componentName: 'DispatchFormModalDraggable',
    positionX: 0,
    positionY: 0,
  },
  mapSetting: {
    title: `Configuração do Mapa`,
    component: <MapSettingModalDraggable />,
    componentName: 'MapSettingModalDraggable',
    open: false,
    disabled: false,
    hide: false,
    minimized: true,
    positionX: 0,
    positionY: 0,
    markersView: {
      entry: {
        title: 'View Entry',
        hide: false, // Mostra a opção
        show: false, // Seleção
      },
      occurrence: {
        title: 'View Occurrences',
        hide: false, // Mostra a opção
        show: false, // Seleção
      },
      dispatch: {
        title: 'View Dispatch',
        hide: false, // Mostra a opção
        show: false, // Seleção
      },
      device: {
        title: 'View Device',
        hide: false, // Mostra a opção
        show: true, // Seleção
        children: {
          connectedDevices: {
            title: 'Connected Devices',
            show: true,
            filterPropName: 'status',
            filterCondition: (id) => Boolean(id != 10),
          },
          disconnectedDevices: {
            title: 'Disconnected Devices',
            show: false,
            filterPropName: 'status',
            filterCondition: (id) => Boolean(id == 10),
          }
        }
      },
      camera: {
        title: 'View Camera',
        hide: false, // Mostra a opção
        show: false, // Seleção
      },
      areas: {
        title: 'View Areas',
        hide: false, // Mostra a opção
        show: false, // Seleção
      },
      interestPoint: {
        title: 'View Interest Point',
        hide: false,
        show: false
      },
      prediction: {
        title: 'View Prevision',
        hide: false,
        show: false
      },
      dispatchFiltered: {
        title: 'View Prevision',
        hide: false,
        show: false
      }
    },
    markersViewExtend: {
      connectedDevices: true,
      disconnectedDevices: false,
    },
    readonly: false,
  },
  occurrenceList: {
    title: `Lista de Ocorrências`,
    component: <OccurrenceListModalDraggable />,
    open: false,
    disabled: true,
    hide: false,
    readonly: false,
    minimized: true,
    componentName: 'OccurrenceListModalDraggable',
    positionX: 0,
    positionY: 0,
  },
  occurrence: {
    title: `Ocorrência`,
    component: <OccurrenceModalDraggable />,
    open: false,
    disabled: true,
    hide: false,
    readonly: false,
    minimized: true,
    componentName: 'OccurrenceModalDraggable',
    positionX: 0,
    positionY: 0,
  },
  searchVehicle: {
    title: `Pesquisar Veículos`,
    component: <SearchVehicleModalDraggable />,
    open: false,
    disabled: true,
    hide: false,
    readonly: false,
    minimized: true,
    componentName: 'SearchVehicleModalDraggable',
    positionX: 0,
    positionY: 0,
    dataSearchVehicle: [],
  },
  searchStaff: {
    title: `Pesquisar Efetivos`,
    component: <SearchStaffModalDraggable />,
    open: false,
    disabled: false,
    hide: false,
    readonly: false,
    minimized: true,
    componentName: 'SearchStaffModalDraggable',
    positionX: 0,
    positionY: 0,
  },
  searchInvolvement: {
    title: `Pesquisar Envolvidos`,
    component: <SearchInvolvementModalDraggable />,
    open: false,
    disabled: true,
    hide: false,
    readonly: false,
    minimized: true,
    componentName: 'SearchInvolvementModalDraggable',
    positionX: 0,
    positionY: 0,
    dataSearchInvolvement: [],
  },
  videoMonitoring: {
    title: 'Vídeo Monitoramento',
    component: <VideoMonitoringModalDraggable />,
    open: false,
    disabled: false,
    hide: false,
    readonly: false,
    componentName: 'VideoMonitoringModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
  },
  entryRecall: {
    title: 'Rechamada',
    component: <EntryRecallModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'EntryRecallModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
  },
  EntryLogModal: {
    title: 'Últimas Ligações',
    component: <EntryLogModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'EntryLogModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
  },
  VehicleModal: {
    title: 'Veículo',
    component: <VehicleModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'VehicleModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
  },
  DispatchUnification: {
    title: 'Unificar despacho',
    component: <DispatchUnificationModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'DispatchUnificationModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
  },
  DispatchDisunification: {
    title: 'Desunificar despacho',
    component: <DispatchDisunificationModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'DispatchDisunificationModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
  },
  registration: {
    title: 'Registrar Qualificadores',
    component: <RegistrationModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'RegistrationModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
  },
  videoMonitoringCards: {
    title: 'Card Monitoriamento',
    component: <VideoMonitoringCardsDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    minimized: true,
    componentName: 'VideoMonitoringCardsDraggable',
    positionX: 0,
    positionY: 0,
  },
  MessageModal: {
    title: `Mensagem`,
    component: <MessageModalDraggable />,
    open: false,
    disabled: true,
    hide: false,
    readonly: false,
    minimized: true,
    componentName: 'MessageModalDraggable',
    positionX: 0,
    positionY: 0,
  },
  entryProcedure: {
    title: 'Procedimentos',
    component: <EntryProcedureModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'EntryProcedureModalDraggable',
    procedure: [],
    positionX: 0,
    positionY: 0,
    minimized: true,
  },
  dispatchProcedure: {
    title: 'Procedimentos',
    component: <DispatchProcedureModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'DispatchProcedureModalDraggable',
    procedure: [],
    positionX: 0,
    positionY: 0,
    minimized: true,
    alreadyOpened: false
  },
  dispatchesResultSearches: {
    title: 'Despachos',
    component: <DispatchResultSearchesModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'DispatchResultSearchesModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
  },
  searchWeapon: {
    title: `Pesquisar Armamentos`,
    component: <SearchWeaponModalDraggable />,
    open: false,
    disabled: false,
    hide: false,
    readonly: false,
    minimized: true,
    componentName: 'SearchWeaponModalDraggable',
    positionX: 0,
    positionY: 0,
    dataSearchWeapon: [],
  },
  mapFilters: {
    title: 'Filtros do Mapa',
    component: <MapFiltersModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'MapFiltersModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
    valueFilter: {
      start_date: null,
      finish_date: null,
      city_ids: []
    }
  },
  mapHistoricFilters: {
    title: 'Seleção de Data',
    component: <MapHistoricFiltersModalDraggable />,
    open: false,
    disabled: false,
    hide: true,
    readonly: false,
    componentName: 'MapHistoricFiltersModalDraggable',
    positionX: 0,
    positionY: 0,
    minimized: true,
  }
});

const systemConfigDefault: ISystemConfig = {
  systemTime: {
    day: 1,
    month: 1,
    year: 1,
    hour: 0,
    minute: 0,
    second: 0
  },
  fontSize: 14,
  groupDispatchName: 'Grupo de Despacho',
  dispatch: 'Despacho',
  maxDispatchesPerDevice: '1',
  emergencyTypeId: '0',
  removeDeviceClosedStatusId: '0',
  mapLatitudeDefault: '0',
  mapLongitudeDefault: '0',
  mapZoomDefault: '0',
  requiredKm: '1',
  requiredPhone: '1',
  boundBottomLeftLat: '0',
  boundBottomLeftLng: '0',
  boundTopRightLat: '0',
  boundTopRightLng: '0',
  emergencyButtonType: '0',
  emergencyButtonUser: '0',
  emergencyButtonDevice: '0',
  occurrenceSearchPeriodInHours: '0',
  allowedEditOccurrenceComment: '0',
  allowedDeleteOccurrenceComment: '0',
  allowedEditEntryComment: '0',
  allowedDeleteEntryComment: '0',
  allowedCommentOnClosedOccurrence: '1',
  displayAllCommentsInPrint: '0',
  displayRequesterDataInPrint: '1',
  allowedEditRequesterDataOfOccurrence: '0',
  allowedEditTypeSubtypeOfOccurrence: '0',
  allowedEditAddressOfOccurrence: '0',
  requestSystemParmsDefault: true,
  allowAnonymousStation: '0',
  displayConsummatedFilterInDashboardAndList: '0',
  enableIntegrationSystemRh: '0',
  requireOccupationOnAssociatedPeople: '0',
  showEntryFieldExternalKeyTracking: '0',
}

const systemConfigState = createState<ISystemConfigState>({
  systemConfig: systemConfigDefault,
})

// eslint-disable-next-line new-cap
systemConfigState.systemConfig.attach(Broadcasted('system-config-values'));

const systemDefault: ISystem = {
  supportWindowRef: null,
  renderComponents: {
    top: TabListDispatch,
    bottom: TabListVehiclesSocket,
  },
  // renderComponents: {
  //   top: TabListDispatchesSocket,
  //   bottom: TabListVehiclesSocket
  // },
}

const loading = createState(false)

// eslint-disable-next-line max-lines-per-function
export default function useSystemState() {
  const { darkMode } = useTheme();
  const { systemConfig } = useState(systemConfigState);
  const loadingButton = useState(loading)


  const theme = useMemo(() => darkMode ? configTheme('dark', systemConfig.fontSize.get()) : configTheme('light', systemConfig.fontSize.get()), [darkMode]);

  useEffect(() => {
    if (systemConfig.requestSystemParmsDefault.get()) {
      getSystemConfigService().then((configs: readonly { readonly name: string; readonly value: string; }[]) => {
        const configKeys = systemConfig.keys;

        configs.forEach((config) => {
          if (configKeys.includes(toCamelCase(config.name) as never)) {
            systemConfig[toCamelCase(config.name)].set(config.value);
          }
        });
      })
      systemConfig.requestSystemParmsDefault.set(false);
    }
  }, [systemConfig.requestSystemParmsDefault.get()])

  const statusColorsStore = useMemo((): IStatusColors => {
    return {
      opened: theme.palette.status.open,
      communicated: theme.palette.status.communicated,
      received: theme.palette.status.received,
      onRoute: theme.palette.status.onRoute,
      arrivalOnSite: theme.palette.status.arrivalOnSite,
      onCarriage: theme.palette.status.onCarriage,
      arrivalInTransport: theme.palette.status.arrivalInTransport,
      closed: theme.palette.status.closed,
      hold: theme.palette.status.hold,
      online: theme.palette.status.online,
      stopService: theme.palette.status.stopService,
      offline: theme.palette.status.offline,
      unified: theme.palette.status.unified,
      tlia: theme.palette.status.tlia,
      total: theme.palette.status.total,
    }
  }, [theme.palette.status]);

  const entryStatusColorsStore = useMemo((): IEntryStatusColors => {
    return {
      prankCall: theme.palette.entryStatus.prankcall,
      reCall: theme.palette.entryStatus.reCall,
      thank: theme.palette.entryStatus.thank,
      transfer: theme.palette.entryStatus.transfer,
      abandonment: theme.palette.entryStatus.abandonment,
      wrongCall: theme.palette.entryStatus.wrongCall,
      information: theme.palette.entryStatus.information,
    }
  }, [theme.palette.entryStatus]);

  const statusColorsPriorityStore: IPriorityColors = useMemo(() => {
    return {
      lowPriority: theme.palette.priority.low,
      mediumPriority: theme.palette.priority.medium,
      highPriority: theme.palette.priority.high,
    }
  }, [theme.palette.priority]);

  const windowManager = useState(windowManagerDefault);

  const { system, statusColors, entryStatusColors, statusColorsPriority } = useState({
    system: systemDefault,
    statusColors: statusColorsStore,
    entryStatusColors: entryStatusColorsStore,
    statusColorsPriority: statusColorsPriorityStore,
  });

  return ({
    theme: theme,
    system: () => system,
    systemConfig: () => systemConfig,
    getSystemTime: () => {
      return dayjs(`${systemConfig.systemTime.year.get()}-${systemConfig.systemTime.month.get()}-${systemConfig.systemTime.day.get()} ${systemConfig.systemTime.hour.get()}:${systemConfig.systemTime.minute.get()}:${systemConfig.systemTime.second.get()}`).format('DD/MM/YYYY HH:mm:ss')
    },
    loadingButton: loadingButton,
    getClusterColors: (type, colorIntensity: string) => {
      const systemColors = {
        'dispatch': {
          'high': {
            'base': '#F5427F',
            'shadow': '#A3535A',
            'brightness': '#F57D92',
          },
          'medium': {
            'base': '#F5C242',
            'shadow': '#A39053',
            'brightness': '#F5D77D',
          },
          'low': {
            'base': '#42f4b6',
            'shadow': '#53a391',
            'brightness': '#7df5d9',
          }
        }
      }

      return systemColors[type][colorIntensity]
    },
    getStatusColors: (id: number | string | null) => {
      if (id == null || id == 0) {
        return statusColors.opened.get()
      } else {
        const systemColors = {
          1: statusColors.opened.get(),
          2: statusColors.communicated.get(),
          3: statusColors.received.get(),
          4: statusColors.onRoute.get(),
          5: statusColors.arrivalOnSite.get(),
          6: statusColors.onCarriage.get(),
          7: statusColors.arrivalInTransport.get(),
          8: statusColors.closed.get(),
          9: statusColors.hold.get(),
          10: statusColors.unified.get(),
          11: statusColors.tlia.get(),
          12: statusColors.total.get(),
        }
        return systemColors[id as number] ?? statusColors.opened.get();
      }
    },
    getInterestPointStatusColors: (id: number) => {
      return Boolean(id) ? { main: '#F51800' } : {
        main: '#0F5FA6'};
      
    },
    getIntensityPrevision: (value, maxValue) => {
      const color = ((value * 100) / maxValue)

      if(color == 0){
        return { main: `#ACF0B4`};
      } else if(color < 20){
        return { main: `#CCE684`};
      } else if (color < 40){
        return { main: `#E6D98B`};
      } else if (color < 60){
        return { main: `#E6C387`};
      } else if (color < 80){
        return { main: `#E5A482`};
      } else {
        return { main: `#E58880`};
      }
      
    },
    getDeviceStatusColors: (id: number | string | null) => {
      const systemColors = {
        1: statusColors.online.get(),
        4: statusColors.communicated.get(),
        5: statusColors.received.get(),
        6: statusColors.onRoute.get(),
        7: statusColors.arrivalOnSite.get(),
        8: statusColors.onCarriage.get(),
        9: statusColors.arrivalInTransport.get(),
        10: statusColors.offline.get(),
        11: statusColors.stopService.get(),
      };

      return systemColors[id as number] ?? statusColors.offline.get();
    },
    getPriorityColors: (priority: number | null) => {
      const systemColorsPriority = {
        1: statusColorsPriority.lowPriority.get(),
        2: statusColorsPriority.lowPriority.get(),
        3: statusColorsPriority.lowPriority.get(),
        4: statusColorsPriority.mediumPriority.get(),
        5: statusColorsPriority.mediumPriority.get(),
        6: statusColorsPriority.mediumPriority.get(),
        7: statusColorsPriority.highPriority.get(),
        8: statusColorsPriority.highPriority.get(),
        9: statusColorsPriority.highPriority.get(),
        10: statusColorsPriority.highPriority.get(),
      }
      return systemColorsPriority[priority as number] ?? statusColors.offline.get();
    },
    getEntryStatusColors: (id: number | string | null = 0) => {
      const colors = {
        1: entryStatusColors.prankCall.get(),
        2: entryStatusColors.reCall.get(),
        4: entryStatusColors.thank.get(),
        5: entryStatusColors.transfer.get(),
        6: entryStatusColors.abandonment.get(),
        7: entryStatusColors.wrongCall.get(),
        8: entryStatusColors.information.get(),
      }
      return colors[id as number] ?? statusColors.offline.get();
    },
    windowManager: () => windowManager,
    windowDefault: (window: ReadonlyArray<string>) => {
      Object.keys(windowManager).map((key) => {
        if (window.includes(key)) {
          windowManager[key].merge({ open: true, disabled: true, hide: true });
        } else {
          windowManager[key].merge({ open: false, disabled: false, hide: false });
        }
      });
    },
    windowIsNotVisible: (window: ReadonlyArray<string>) => {
      Object.keys(windowManager).map((key) => {
        if (window.includes(key)) {
          windowManager[key].merge({ hide: false });
        } else {
          windowManager[key].merge({ hide: true });
        }
      });
    },
    windowIsVisible: (window: ReadonlyArray<string>) => {
      Object.keys(windowManager).map((key) => {
        if (window.includes(key)) {
          windowManager[key].merge({ hide: true });
        } else {
          windowManager[key].merge({ hide: false });
        }
      });
    },
    windowReadOnly: (window: ReadonlyArray<string>) => {
      Object.keys(windowManager).map((key) => {
        if (window.includes(key)) {
          const hideWindow = key === 'entry' || key === 'searchVehicle' || key === 'searchInvolvement';
          windowManager[key].merge({ hide: hideWindow, readonly: true });
        } else {
          windowManager[key].merge({ readonly: false });
        }
      });
    },
    markersViewVisible: (markersVisible: ReadonlyArray<string>) => {
      Object.keys(windowManager['mapSetting']['markersView']).map((key) => {
        if (markersVisible.includes(key)) {
          windowManager['mapSetting']['markersView'][key].merge({ hide: true });
        } else {
          windowManager['mapSetting']['markersView'][key].merge({ hide: false, show: false });
        }
      });
    },
    markersViewDefault: (markersVisible: ReadonlyArray<string>) => {
      Object.keys(windowManager['mapSetting']['markersView']).map((key) => {
        if (markersVisible.includes(key)) {
          windowManager['mapSetting']['markersView'][key].merge({ show: true });
        }
      });
    },
    loadDraggables: (isVisibles: Array<string>) => {
      loadingButton.set(true)
      getUserSettings(window.location.pathname).then((response) => {
        Object.keys(windowManager).map((draggable) => {
          if (response.data[windowManager[draggable]['componentName'].get()] && !isVisibles.includes(response.data[windowManager[draggable]['componentName'].get()].window_name)) {
            windowManager[draggable].positionX.set(Number(response.data[windowManager[draggable]['componentName'].get()].x_position))
            windowManager[draggable].positionY.set(Number(response.data[windowManager[draggable]['componentName'].get()].y_position))
            windowManager[draggable].open.set(response.data[windowManager[draggable]['componentName'].get()].is_window_open)
            windowManager[draggable].minimized.set(response.data[windowManager[draggable]['componentName'].get()].is_window_minimized)

          } else {
            false
          }
        })
      }).finally(() => {
        loadingButton.set(false)
      })
    },
    verifyOpenDraggables: () => {

    },
    colors: {
      status: {
        opened: statusColors.opened.get(),
        communicated: statusColors.communicated.get(),
        received: statusColors.received.get(),
        onRoute: statusColors.onRoute.get(),
        arrivalOnSite: statusColors.arrivalOnSite.get(),
        onCarriage: statusColors.onCarriage.get(),
        arrivalInTransport: statusColors.arrivalInTransport.get(),
        closed: statusColors.closed.get(),
        hold: statusColors.hold.get(),
        unified: statusColors.unified.get(),
        tlia: statusColors.tlia.get(),
        total: statusColors.total.get(),
      },
      deviceStatus: {
        online: statusColors.online.get(),
        communicated: statusColors.communicated.get(),
        received: statusColors.received.get(),
        onRoute: statusColors.onRoute.get(),
        arrivalOnSite: statusColors.arrivalOnSite.get(),
        onCarriage: statusColors.onCarriage.get(),
        arrivalInTransport: statusColors.arrivalInTransport.get(),
        offline: statusColors.offline.get(),
        stopService: statusColors.stopService.get()
      },
      entryStatys: {
        prank: entryStatusColors.prankCall.get(),
        reCall: entryStatusColors.reCall.get(),
        thank: entryStatusColors.thank.get(),
        transfer: entryStatusColors.transfer.get(),
        abandonment: entryStatusColors.abandonment.get(),
        wrongCall: entryStatusColors.wrongCall.get(),
        information: entryStatusColors.information.get(),
      },
      priority: {
        lowPriority: statusColorsPriority.lowPriority.get(),
        mediumPriority: statusColorsPriority.mediumPriority.get(),
        highPriority: statusColorsPriority.highPriority.get()
      }
    },
  })
};
