/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { Button, Grid, Chip, Tooltip, Paper, CircularProgress, Avatar } from '@mui/material';
import { State } from '@hookstate/core';
import { elapsedTime } from '../../utils/dateTime';
import { useHookstate } from '@hookstate/core';
import ChangeStatusVehicle from '../../components/ChangeStatusVehicle';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import useSystemState from '~/features/System/stores/SystemState';
import IDispacthVehicle from '~/features/Dispatch/interfaces/IDispacthVehicle';
import { useMarkerState } from '~/features/Map/stores/MapState';
import { LatLng } from 'leaflet';
import TimelineDeviceStatus from '../TimelineDeviceStatus';
import { getTimelineDevice } from '../TimelineDeviceStatus/services';
import { useLang } from '~/hooks/useLang';
import RefreshIcon from '@mui/icons-material/Refresh';

type Props = {
  readonly data: State<Array<IDispacthVehicle>>,
  readonly readonly: boolean
  readonly loading?: boolean;
  readonly refresh?: boolean;
  readonly refreshAction?: () => void;
}

export interface TimeLineProps {
  readonly id: number;
  readonly name: string;
  readonly from: string;
  readonly duration: string | null;
}

export default function DeviceDispatchListTable({ data, readonly, loading = false, refresh = false, refreshAction }: Props) {
  const { dispatch } = useDispatchState();
  const { getStatusColors, systemConfig } = useSystemState();
  const { markersState, forceViewMap } = useMarkerState();
  const atualizar = useHookstate(false);
  const tickTime = useHookstate(60000);
  const openChangeStatusVehicle = useHookstate(false);
  const statusCurrent = useHookstate({ id: '', name: '', acronym: '' });
  const dispatchDeviceId = useHookstate<number | null>(null);
  const deviceId = useHookstate<number | null>(null);
  const timeLine = useHookstate<TimeLineProps[]>([])
  const deviceCode = useHookstate('');
  const openTimelineDeviceStatus = useHookstate(false)
  const { translate } = useLang()

  useEffect(() => {
    const ticktimeGet = tickTime.get();
    const intervalSrc = setInterval(() => atualizar.set(true), ticktimeGet);

    return () => clearInterval(intervalSrc);
  }, [tickTime.get()]);

  useEffect(() => {
    atualizar.set(false);
  }, [atualizar.get()]);




  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 25 },
    {
      field: 'identification',
      headerName: 'Identificação',
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={translate('Locate on the map')}>
            <Chip
              disabled={!(markersState()['device'].get()[params.row.device_id]?.position)}
              label={params.value}
              onClick={() => forceViewMap().position.set(new LatLng(markersState()['device'][params.row.device_id].position.lat.get(), markersState()['device'][params.row.device_id].position.lng.get()))}
            />
          </Tooltip>
        )
      }
    },
    { field: 'status_id', headerName: 'Status ID', flex: 1 },
    { field: 'status_name', headerName: 'Status Name', flex: 1 },

    {
      field: "status",
      headerName: "Status",
      sortable: false,
      headerAlign: 'center',
      flex: 1.6,
      renderCell: (params) => {
        const changeStatusVehicleAction = () => {
          deviceId.set(params.row.device_id);
          deviceCode.set(params.row.identification);
          dispatchDeviceId.set(params.row.id);
          statusCurrent.set({ id: params.row.status_id.toString(), name: params.row.status_name, acronym: params.row.status });
          openChangeStatusVehicle.set(true);
        }
        return (
          <Button
            type="submit"
            variant="contained"
            disabled={readonly}
            onClick={changeStatusVehicleAction}
            sx={{
              backgroundColor: getStatusColors(params.row.status_id).main,
              color: getStatusColors(params.row.status_id).contrastText,
              '&:hover': {
                backgroundColor: getStatusColors(params.row.status_id).dark,
                color: getStatusColors(params.row.status_id).contrastText,
              },
              '&:disabled': {
                backgroundColor: getStatusColors(params.row.status_id).main,
                color: getStatusColors(params.row.status_id).contrastText,
              }
            }}>
            {params.value}
          </Button>
        )
      }
    },


    {
      field: 'status_changed_at',
      headerName: 'Tempo Transc.',
      flex: 2,
      align: 'center',
      renderCell: (params) => {
        const timelineDeviceStatusAction = () => {
          openTimelineDeviceStatus.set(true);
          getTimelineDevice({ dispatchDeviceId: params.row.id })
            .then((response) => timeLine.set(response.data))
        }
        return (
          <Tooltip title={translate('Chronology device')}>
            <Chip
              disabled={readonly}
              onClick={
                timelineDeviceStatusAction}
              label={elapsedTime(params.row.status_changed_at)}
              clickable
            />
          </Tooltip>
        )
      }
    }
  ];

  const rows = data.get()
    .map((dispatchDevice) => ({
      id: dispatchDevice['id'],
      device_id: dispatchDevice['device']['id'],
      identification: dispatchDevice['device']['name'],
      status_id: dispatchDevice['status']['id'],
      status_name: dispatchDevice['status']['name'],
      status: dispatchDevice['status']['acronym'],
      status_changed_at: dispatchDevice['status_changed_at'],
    }));

  return (
    <Grid sx={{ height: systemConfig().displayRequesterDataInPrint.get() === '1' ? '76%' : '100%', width: '100%' }}>
      {
        !loading ? !refresh ?
          <Grid height={'410px'}>
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            columnVisibilityModel={{
              id: false,
              status_id: false,
              status_name: false
            }}
            disableColumnSelector={true}
            hideFooterPagination={true}
            hideFooter={true}
            sx={{
              boxShadow: 2,
              '& .MuiDataGrid-cell:hover': {
                color: 'primary',
              },
            }}
            />
          </Grid>
          : <Chip sx={{ marginLeft: '0%', marginTop: '50%' }} label={translate("Connection failed! Please try again")} avatar={<Avatar><RefreshIcon /></Avatar>} onClick={() => refreshAction ? refreshAction() : false} />

          : <Paper
            variant='outlined'
            sx={{
              width: '100%',
              minHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',


            }}><CircularProgress disableShrink /></Paper>
      }

      <ChangeStatusVehicle
        openState={openChangeStatusVehicle}
        dispatchId={dispatch().id}
        dispatchDeviceId={dispatchDeviceId}
        deviceId={deviceId}
        deviceCode={deviceCode}
        statusCurrent={statusCurrent}
      />
      <TimelineDeviceStatus
        openState={openTimelineDeviceStatus}
        timeLinePoint={timeLine}
      />
    </Grid>
  );
}
