import React, { useEffect } from 'react';
import { useHookstate } from '@hookstate/core';
interface ImageRendererProps {
    base64?: boolean,
    alt?: string
    src: string,
    height: string,
}

export default function ImageRenderer({ base64 = false, alt = 'Image', src, height }: ImageRendererProps) {
    const srcState = useHookstate(`data: image / png; base64, ${src}`);

    const mountImage = () => {
        if (base64) return `data:image/png;base64,${src}`;

        return src; 
    }

    useEffect(() => {
        srcState.set(mountImage())
    }, [src])


    return <img src={srcState.get()} alt={alt} height={height} />;
}
