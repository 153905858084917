/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import { Downgraded, State, StateMethods, useHookstate } from '@hookstate/core';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams, GridSlots, type GridRowId } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { Box, Button, Chip, LinearProgress, Tooltip } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useSystemState from '~/features/System/stores/SystemState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { toDomain } from '~/mappers/vehicles-mapper';
import MenuList from './MenuList';
import useListVehiclesSocket from '../stores/ListVehiclesSocketState';
import ConectDispatchVehicle from '~/components/ConectDispatchVehicle';
import DisplayDispatchVehicle from '~/components/DisplayDispatchVehicle';
import OutOfServiceDispatchVehicle from '~/components/OutOfServiceDispatchVehicle';
import ReturnToServiceDispatchVehicle from '~/components/ReturnToServiceDispatchVehicle';
import DisconnectDispatchVehicle from '~/components/DisconnectDispatchVehicle';
import notify from '~/utils/notify';
import { parseDataDispatch } from '~/features/Dispatch/utils/parsers';
import { IListVehicleSocket, IListVehicleSocketResponse } from '../interfaces/IListVehiclesProps';
import { getVehiclesByStatus } from '../services';
import { IFilters } from '~/components/ListDispatchesSocket/services';
import { DeviceTransferDialog } from '~/features/Device/components/DeviceTransferDialog';
import { dateTimeFormat, elapsedTime } from '~/utils/dateTime';
import TimelineDeviceStatus from '~/components/TimelineDeviceStatus';
import { getTimelineDevice } from '~/components/TimelineDeviceStatus/services';
import ChronologyDeviceLastDispatchDialog from '~/components/ChronologyDeviceLastDispatchButton/components/ChronologyDeviceLastDispatchDialog';
interface IListVehiclesSocket {
  readonly targetUrl: string;
  readonly state: State<readonly IListVehicleSocket[]>;
  readonly rowsSelected?: State<readonly IListVehicleSocket[]>
  readonly enableCheckbox?: boolean;
  readonly searchVehicle: string;
  readonly searchTask: string;
  readonly statusId: readonly number[];
  readonly exceptColumns?: readonly string[];
  readonly loading: StateMethods<boolean>;
  readonly limit: StateMethods<number>;
  readonly page: StateMethods<any>;
  readonly fullList?: boolean;
  readonly rowsCount: number;
  readonly filters: State<readonly IFilters[]>;
  readonly handleLoadDevices: () => void
  readonly readOnly?: boolean;
  readonly handleLoadAllVehicles?: () => void;
}

export default function ListVehiclesSocket({ filters, fullList = false, handleLoadDevices, rowsCount, limit, page, loading, searchVehicle, searchTask, targetUrl, state, statusId, exceptColumns = [], readOnly = false, enableCheckbox, rowsSelected, handleLoadAllVehicles }: IListVehiclesSocket) {
  const { translate } = useLang();
  const { getDispatchById, syncDispatchFromPayload } = useDispatchState();
  const { getStatusColors, getDeviceStatusColors } = useSystemState();
  const { handleMenuActions, returnToServiceVehicle, disconnectVehicle, listVehiclesState } = useListVehiclesSocket();
  const {
    openConectDispatchVehicle,
    openTransferDevice,
    openCrewDialog,
    openOutOfServiceDialog,
    openReturnToServiceDialog,
    openDisconnectDialog,
    isOpenDialog,
    lockTemporaryTracking,
    deviceId,
    agencyId,
    identification,
    plate,
    canEditCrew,
    labelCommander,
    labelDriver,
    labelPatrolmans,
    labelKmInitial,
    openChronologyVehicle,
    dispatchGroup,
  } = listVehiclesState()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [rows, setRows] = useState<readonly IListVehicleSocketResponse[]>([]);
  const [rowsCountLocal, setRowsCountLocal] = useState<number>(rowsCount);
  const open = Boolean(anchorEl);
  const id = open ? 'menu-appbar' : '';
  const mode = fullList ? 'client' : 'server';
  const atualizar = useHookstate(false);
  const tickTime = useHookstate(60000);


  useEffect(() => {
    const ticktimeGet = tickTime.get();
    const intervalSrc = setInterval(() => atualizar.set(true), ticktimeGet);

    return () => clearInterval(intervalSrc);
  }, [tickTime.get()]);

  useEffect(() => {
    atualizar.set(false);
  }, [atualizar.get()]);

  const handleImportDispatch = (params: GridRenderCellParams) => {
    isOpenDialog.set(true);
    getDispatchById(params.row.dispatchId)
      .then((dispatchData) => {
        const dispatchParsed = parseDataDispatch(dispatchData);
        const { id } = dispatchParsed;
        if (!id) { throw new Error('Trying to use a dispatch without Id') }
        syncDispatchFromPayload({ dispatchParams: { dispatch: dispatchParsed }, canCreateDispatch: true, canSaveDispatch: true }).then(() => { isOpenDialog.set(false) }).catch(err => console.error(err));
      }).catch(err => console.error(err));
  };

  const columns: GridColDef[] = ([
    { field: 'id' },
    { field: 'agencyId' },
    {
      field: 'name', headerName: translate('Identification'), flex: 1, renderCell: (params) => {
        return (
          <Chip
            sx={{
              backgroundColor: getDeviceStatusColors(params.row.statusId).main,
              color: getDeviceStatusColors(params.row.statusId).contrastText,
              '&:hover': {
                backgroundColor: getDeviceStatusColors(params.row.statusId).dark,
                color: getDeviceStatusColors(params.row.statusId).contrastText,
              },
            }}
            label={params.row.identification} />
        )
      }
    },
    { field: 'commander', headerName: translate('Commander'), flex: 1.5, renderCell: (params) => params.row.commander },
    { field: 'phone', headerName: translate('Phone'), flex: 0.70, renderCell: (params) => params.row.phone },
    { field: 'task', headerName: translate('Task'), flex: 1.5 },
    { field: 'notes', headerName: translate('Notes'), flex: 1, renderCell: (params) => params.row.notes },
    { field: 'dispatchGroupId', headerName: translate('Dispatch Group'), flex: 1, renderCell: (params) => params.row.dispatchGroup },
    { field: 'type', headerName: translate('Type'), flex: 1, renderCell: (params) => params.row.type },
    { field: 'subtype', headerName: translate('Subtype'), flex: 1, renderCell: (params) => params.row.subtype },
    {
      field: 'statusChangedAt', headerName: translate('Latest status update'), flex: 1, align: 'center', renderCell: (params) => (
        <Tooltip title={`Status atualizado em: ${dateTimeFormat(params.row.statusChangedAt)}`} placement='top'>
          <Chip variant={'outlined'} label={elapsedTime(params.row.statusChangedAt)} />
        </Tooltip>
      )
    },
    {
      field: 'deviceStatusId', headerName: translate('Status'), flex: 0.6, align: 'center',
      renderCell: (params) => (
        <Button type='submit' variant='contained'
          sx={{
            backgroundColor: getDeviceStatusColors(params.row.statusId).main,
            color: getDeviceStatusColors(params.row.statusId).contrastText,
            '&:hover': {
              backgroundColor: getDeviceStatusColors(params.row.statusId).dark,
              color: getDeviceStatusColors(params.row.statusId).contrastText,
            },
          }}>
          {params.row.status}
        </Button>
      )
    },
    { field: 'outOfServiceMotive', headerName: translate('Out Of Service Reason'), flex: 1, renderCell: (params) => params.row.outOfServiceReason },
    {
      field: 'outOfServiceDate', headerName: translate('Out Of Service Date'), flex: 1, align: 'center', renderCell: (params) => (
        <Tooltip title={`Fora de Serviço em: ${dateTimeFormat(params.row.outOfServiceDate)}`} placement='top'>
          <Chip variant={'outlined'} label={elapsedTime(params.row.outOfServiceDate)} />
        </Tooltip>
      )
    },
    {
      field: 'connectionDate', headerName: translate('Connection Date'), flex: 1, align: 'center', renderCell: (params) => (
        <Tooltip title={`Conectada em: ${dateTimeFormat(params.row.availableDate)}`} placement='top'>
          <Chip variant={'outlined'} label={elapsedTime(params.row.availableDate)} />
        </Tooltip>
      )
    },
    {
      field: 'disconnectionDate', headerName: translate('Disconnection Date'), flex: 1, align: 'center', renderCell: (params) => (
        <Tooltip title={params.row.disconnectedDate ? `Desconectada em: ${dateTimeFormat(params.row.disconnectedDate)}` : translate('This vehicle has never been connected')} placement='top'>
          <Chip variant={'outlined'} label={params.row.disconnectedDate ? elapsedTime(params.row.disconnectedDate) : translate('Never connected')} />
        </Tooltip>
      )
    },

    { field: 'statusId', headerName: translate('Status ID'), flex: 1, renderCell: (params) => params.row.statusId },
    { field: 'dispatchId', headerName: translate('Dispatch'), flex: 1 },
    { field: 'dispatchStatus', headerName: translate('Dispatch Status'), flex: 1 },
    {
      field: 'dispatch', headerName: translate('Dispatch'), flex: 1, align: 'center',
      renderCell: (params) => {
        if (params.row.dispatchId) {
          return (
            <Button type='submit' variant='contained'
              onClick={() => handleImportDispatch(params)}
              sx={{
                backgroundColor: getStatusColors(params.row.dispatchStatus).main, color: getStatusColors(params.row.dispatchStatus).contrastText, '&:hover': { backgroundColor: getStatusColors(params.row.dispatchStatus).dark, color: getStatusColors(params.row.dispatchStatus).contrastText, },
              }}>
              {params.row.dispatch}
            </Button>
          )
        } return '-';
      },
    },
    { field: 'comment', headerName: translate('Comment'), flex: 1 },
    {
      field: 'actions', type: 'actions', headerName: '', flex: 0.2, getActions: ({ id, row }) => [
        <GridActionsCellItem
          key={id}
          icon={<MoreVertIcon />}
          label='export'
          onClick={(event) => {
            setAnchorEl(event?.currentTarget ?? null);
            handleMenuActions(row);
          }} />,]
    },
  ]);

  const filterColumns = () => {
    const newColumn: GridColDef[] = []
    columns.map((value) => {
      if (!exceptColumns.includes(value.field)) {
        newColumn.push(value)
      }
    })
    return newColumn;
  }
  const handleClose = () => { setAnchorEl(null); };

  const handleReturnToServiceVehicle = async () => {
    loading.set(true)
    await returnToServiceVehicle().then((response) => {
      if (response?.status === 200) {
        notify({ message: translate(response?.data.message), type: 'success' });
      }
    }).finally(() => {
      openReturnToServiceDialog.set(false)
      deviceId.set(null)
      loading.set(false)
    })
  };

  const handleDisconnectVehicle = async () => {
    loading.set(true)
    await disconnectVehicle().then((response) => {
      if (response?.status === 200) {
        notify({ message: translate(response?.data.message), type: 'success' });
      }
    }).finally(() => {
      openDisconnectDialog.set(false)
      deviceId.set(null)
      loading.set(false)
    })
  };

  const loadLocalDevices = () => {
    setRows([])
    getVehiclesByStatus({
      filters: filters.get(),
      page: page.get(),
      limit: limit.get(),
      withoutPagination: fullList,
      dispatchId: null,
      targetUrl: targetUrl
    })
      .then(devices => {
        const rowsFormated = devices.data.map(row => toDomain(row));
        setRows(rowsFormated)
        setRowsCountLocal(devices.rowsCount)
      })
  }

  useEffect(() => {
    loadLocalDevices()
  }, [page.attach(Downgraded).get()])

  useEffect(() => {
    if (searchVehicle && !searchTask) {
      if (searchVehicle.length > 2) {
        filters.set([
          {
            field: 'name',
            operator: 'contains',
            value: searchVehicle
          },
        ]);
        page.set(0);
        loadLocalDevices()
      }
    } else if (searchTask && !searchVehicle && targetUrl !== '/device/disconnected') {
      if (searchTask.length > 2) {
        filters.set([
          {
            field: 'task',
            operator: 'contains',
            value: searchTask
          },
        ]);
        page.set(0);
        loadLocalDevices()
      }
    } else if (searchTask && searchVehicle && targetUrl !== '/device/disconnected') {
      filters.set([
        {
          field: 'task',
          operator: 'contains',
          value: searchTask
        },
        {
          field: 'name',
          operator: 'contains',
          value: searchVehicle
        },
      ]);
      page.set(0);
      loadLocalDevices()
    } else {

      filters.set([])
      // loadLocalDevices()
    }
  }, [searchVehicle, searchTask])

  useEffect(() => {
    if (searchVehicle || searchTask) {
      return
    }
    setRows(state.get().map(row => toDomain(row)))
    setRowsCountLocal(rowsCount)
  }, [state, rowsCount])


  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    rowsSelected?.set(selectedRowsData)
  };

  return (
    <>
      <DataGrid
        sx={{ minHeight: '250px' }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={filterColumns()}
        columnVisibilityModel={{
          id: false,
          agencyId: false,
          statusId: false,
          dispatchId: false,
          dispatchStatus: false,
        }}
        loading={loading.get()}
        slots={{ loadingOverlay: LinearProgress as GridSlots['loadingOverlay'] }}
        slotProps={{ row: { style: { cursor: 'pointer' } } }}
        getRowId={(row) => Number(row.id)}
        onPaginationModelChange={(pageContent) => {
          if (!fullList) {
            page.set(pageContent.page)
            handleLoadDevices()
          }
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: limit.get() } },
        }}
        rowCount={rowsCountLocal}
        paginationMode={mode}
        hideFooterPagination={rowsSelected?.get().length ? true : false}
        pageSizeOptions={[]}
        checkboxSelection={enableCheckbox}
        onRowSelectionModelChange={(ids) => {
          onRowsSelectionHandler(ids)
        }}
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnMenu
        autoHeight
      />
      <MenuList anchorEl={anchorEl} handleClose={handleClose} id={id} readOnly={readOnly} />
      <ConectDispatchVehicle
        lockTemporaryTracking={lockTemporaryTracking.get()}
        openState={openConectDispatchVehicle}
        identification={identification.get()}
        deviceId={deviceId}
        plate={plate.get()}
        labelCommander={labelCommander.get()}
        labelDriver={labelDriver.get()}
        labelPatrolmans={labelPatrolmans.get()}
        labelKmInitial={labelKmInitial.get()}
      />
      {
        targetUrl == '/device/disconnected' || targetUrl == '/device/available' ?
          <DeviceTransferDialog
            title={translate('Transfer Device')}
            deviceId={deviceId.get()}
            deviceName={identification.get()}
            isOpen={openTransferDevice}
            agencyId={agencyId.get()}
            handleLoadAllVehicles={handleLoadAllVehicles}
            dispatchGroup={dispatchGroup.get()}
            fields={[
              { name: translate('To Be Return At'), model: 'to_be_returned_at', type: 'date' },
              { name: translate('Destiny Name'), model: 'destiny_name' },
              { name: translate('Destiny Group'), model: 'dest_dispatch_group_id', type: 'select', required: true }
            ]}
          />
          : false
      }
      <DisplayDispatchVehicle
        handleLoadDevices={handleLoadDevices}
        lockTemporaryTracking={lockTemporaryTracking.get()}
        deviceId={deviceId}
        plate={plate.get()}
        open={openCrewDialog}
        deviceCode={identification}
        canEditCrew={canEditCrew.get()}
        readOnly={readOnly}
        labelCommander={labelCommander.get()}
        labelDriver={labelDriver.get()}
        labelPatrolmans={labelPatrolmans.get()}
        labelKmInitial={labelKmInitial.get()}
      />
      <ChronologyDeviceLastDispatchDialog
        id={deviceId.get()}
        open={openChronologyVehicle}
      />
      <OutOfServiceDispatchVehicle
        openState={openOutOfServiceDialog}
        identification={identification.get()}
        deviceId={deviceId.get()}
      />
      <ReturnToServiceDispatchVehicle
        openState={openReturnToServiceDialog}
        handleAgree={handleReturnToServiceVehicle}
        handleClose={() => { openReturnToServiceDialog.set(false) }}
        isLoading={loading}
      />
      <DisconnectDispatchVehicle
        identification={identification.get()}
        openState={openDisconnectDialog}
        handleAgree={handleDisconnectVehicle}
        handleClose={() => { openDisconnectDialog.set(false) }}
        isLoading={loading}
        disabled={!deviceId.get()}
      />
    </>
  )
}