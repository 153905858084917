/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
} from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationPersonState from '../../../../stores/DispatchRegistrationPersonState';
import useDispatchRegistrationState from '../../../../stores/DispatchRegistrationState';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { calculateAge, maskCPF, maskNames, maskPhoneNumber, removeMask, validate } from '../../../../utils/stringsManipulations';
import IGender from '~/features/Gender/interfaces/IGender';
import IDocumentType from '~/features/DocumentType/interfaces/IDocumentType';
import IEthnicity from '~/features/Ethnicity/interfaces/IEthnicity';
import IEducation from '~/features/Education/interfaces/IEducation';
import ptBR from 'dayjs/locale/pt-br';
import FieldAutocompleteRegistration from '~/components/FieldAutocompleteRegistration';
import { searchInvolvement } from '~/features/User/services';
import useSystemState from '~/features/System/stores/SystemState';
import { useSearchCPF as searchExternalCpf} from '~/features/User/components/SearchInvolvement';
import { useHookstate } from '@hookstate/core';
import SearchIcon from '@mui/icons-material/Search';

interface FormPeoplePersonalDataProps {
  readonly disableFields?: boolean
}


export default function FormPeoplePersonalData({ disableFields = false }: FormPeoplePersonalDataProps) {
  const { translate } = useLang();
  const { windowManager } = useSystemState();
  const { systemConfig } = useSystemState();

  const {
    registrationDispatch,
  } = useDispatchRegistrationState();

  const { inputErrors } = registrationDispatch();

  const {
    registrationPersonDispatch,
    getDocumentTypes,
    getGenders,
    getEthnicities,
    getSchooling,
    getOccupation
  } = useDispatchRegistrationPersonState();

  const { person } = registrationPersonDispatch();

  const [birthDateValue, setBirthDate] = useState<Dayjs | null>(null)
  const [documentTypesList, setDocumentTypes] = useState<readonly IDocumentType[]>([]);
  const [gendersList, setGenders] = useState<readonly IGender[]>([]);
  const [ethnicitiesList, setEthnicities] = useState<readonly IEthnicity[]>([]);
  const [schoolingList, setSchooling] = useState<readonly IEducation[]>([]);
  const changeExternalCpf = searchExternalCpf().cpf()
  const resetCpfSearch = useHookstate(false)


  useEffect(() => {
    const cpfFormated = removeMask(person.get().cpf)

    if(cpfFormated.length == 11){
      searchInvolvement({
        cpf: cpfFormated
      }).then(({data})=> {
        if(data.length > 0){
          windowManager()['searchInvolvement'].open.set(true)
          changeExternalCpf.set(cpfFormated)
          windowManager()['searchInvolvement'].dataSearchInvolvement.set(data)
        }else{
          windowManager()['searchInvolvement'].open.set(false)
          windowManager()['searchInvolvement'].dataSearchInvolvement.set([])
        }
      })
    }else{
      windowManager()['searchInvolvement'].open.set(false)
      windowManager()['searchInvolvement'].dataSearchInvolvement.set([])
    }

  },[person.get().cpf, resetCpfSearch.get()])


  useEffect(() => {
    async function loadData() {
      const date = dayjs(person.get().birthDate, 'YYYY-MM-DD')
      setBirthDate(date.isValid() ? date : null)
      person.merge({
        birthDate: date.isValid() ? date.format('YYYY-MM-DD') : null
      })
    }
    loadData()
  }, [])

  return (
    <FormControl fullWidth size='small' >
      <Grid container direction={'row'}>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Gender')}
            onValueChange={(gender) => person.gender.set(gender)}
            valueId={person.gender.get()?.id as string}
            optionsFetch={getGenders}
            isRequired
            disabled={disableFields}
            error={inputErrors.gender.get()}
            onError={(value) => inputErrors.gender.set(value)}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Occupation')}
            onValueChange={(occupation) => person.occupation.set(occupation)}
            valueId={person.occupation.get()?.id as string}
            optionsFetch={getOccupation}
            isRequired={Boolean(parseInt(systemConfig().requireOccupationOnAssociatedPeople.get()))}
            disabled={disableFields}
            error={inputErrors.occupation.get()}
            onError={(value) => inputErrors.occupation.set(value)}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            error={!!inputErrors.get().cpf}
            size='small'
            id="outlined-cpf"
            label={translate('CPF')}
            onKeyDown={(event) => {
              if (event.code === "NumpadEnter" || event.code === "Enter") {
                resetCpfSearch.set(!resetCpfSearch.get())
              }
            }}
            value={person.get().cpf}
            InputProps={{
              onChange: (event) => {
                const cpf = maskCPF(event.target.value)
                person.merge({
                  cpf
                })
                if (validate(cpf) || !cpf.length) {
                  inputErrors.cpf.set(false)
                } else {
                  inputErrors.cpf.set(true)
                }
              },
              endAdornment: (
                <IconButton disabled={disableFields} onClick={() => {
                  resetCpfSearch.set(!resetCpfSearch.get())
                }}>
                  <SearchIcon />
                </IconButton>
              )
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-name"
            label={translate('Name')}
            value={person.get().name}
            InputProps={{
              onChange: (event) => {
                person.merge({
                  name: maskNames(event.target.value)
                })
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-nickname"
            label={translate('Nickname')}
            value={person.get().nickname}
            InputProps={{
              onChange: (event) => person.merge({
                nickname: maskNames(event.target.value)
              }),
            }}
          />
        </Grid>
        
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Document Type')}
            onValueChange={(type) => {
              person.merge({
                otherDocument: type,
                otherDocumentType: ''
              })
              if (type?.id) {
                inputErrors.document.set(true)
              } else {
                inputErrors.document.set(false)
              }
            }}
            valueId={person.otherDocument.get()?.id as string}
            optionsFetch={getDocumentTypes}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <TextField
            fullWidth
            size='small'
            id="outlined-document-value"
            error={!!inputErrors.get().document}
            label={translate('Document')}
            value={person.get().otherDocumentType}
            disabled={!person.get().otherDocument || disableFields}
            InputProps={{
              onChange: (event) => {
                const otherDocumentType = event.target.value
                person.merge({
                  otherDocumentType
                })
                if (otherDocumentType.length) {
                  inputErrors.document.set(false)
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            error={!!inputErrors.get().phoneNumber}
            onFocus={() => inputErrors.phoneNumber.set(false)}
            fullWidth
            size='small'
            id="outlined-phone-number"
            label={translate('Phone Number')}
            value={person.get().phoneNumber}
            InputProps={{
              onChange:
                (event) => {
                  const phoneNumber = maskPhoneNumber(event.target.value)
                  person.merge({
                    phoneNumber,
                  })
                  if (phoneNumber.length > 0 && phoneNumber.length < 11) {
                    inputErrors.phoneNumber.set(true)
                  } else {
                    inputErrors.phoneNumber.set(false)
                  }
                },
            }}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
            <DateTimePicker
              disabled={disableFields}
              disableFuture
              views={['day', 'month', 'year']}
              minDate={dayjs().subtract(110, 'years')}
              label={translate(`Birth Date`)}
              format="DD/MM/YYYY"
              value={birthDateValue}
              onChange={(newValue: Dayjs | null) => {
                setBirthDate(newValue);

                if (!newValue) {
                  inputErrors.birthDate.set(false)
                  person.merge({
                    birthDate: null
                  })
                  return
                }
                const age = calculateAge(newValue);
                if (age < 0 || age > 110) {
                  person.merge({
                    age: 0,
                  })
                  inputErrors.birthDate.set(true)
                } else {
                  person.merge({
                    age,
                    birthDate: newValue.format('YYYY-MM-DD')
                  })
                  inputErrors.birthDate.set(false)
                }

              }}
              slotProps={{ textField: {
                error: !!inputErrors.get().birthDate,
                size: 'small',
                inputProps: {
                  placeholder: translate(`dd/mm/yyyy`)
                }
              } }}
              // renderInput={(params) =>
              //   <TextField
              //     {...params}
              //     error={!!inputErrors.get().birthDate}
              //     size='small'
              //     inputProps={{
              //       ...params.inputProps,
              //       placeholder: translate(`dd/mm/yyyy`)
              //     }}
              //   />
              // }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1.25} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-age"
            label={translate('Age')}
            value={person.get().age}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 0, max: 100 },
              onChange: (event) => {
                const age = event.target.value;
                person.merge({
                  age: age
                })
              },
            }}
          />
        </Grid>
        <Grid item xs={3.75} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Ethnicity')}
            onValueChange={(ethnicity) => {
              person.merge({ ethnicity })
            }}
            valueId={person.ethnicity.get()?.id as string}
            optionsFetch={getEthnicities}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={7} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Education')}
            onValueChange={(education) => {
              person.merge({ education })
            }}
            valueId={person.education.get()?.id as string}
            optionsFetch={getSchooling}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-mother-name"
            label={translate('Mother Name')}
            value={person.get().motherName}
            InputProps={{
              onChange: (event) => person.merge({
                motherName: maskNames(event.target.value)
              }),
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-father-name"
            label={translate('Father Name')}
            value={person.get().fatherName}
            InputProps={{
              onChange: (event) => person.merge({
                fatherName: maskNames(event.target.value)
              }),
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ padding: 1 }}>
          <FormLabel component="legend">
            {translate('Conditions')}
          </FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              checked={person.get().hasMentalIllness}
              control={<Checkbox disabled={disableFields} />}
              label={translate("Mental Illness")}
              labelPlacement="end"
              onChange={(event: unknown, hasMentalIllness: boolean) => {
                person.merge({ hasMentalIllness })
              }}
            />
            <FormControlLabel
              checked={person.get().hasPhysicalIllness}
              control={<Checkbox disabled={disableFields} />}
              label={translate("Physical Illness")}
              labelPlacement="end"
              onChange={(event: unknown, hasPhysicalIllness: boolean) => {
                person.merge({ hasPhysicalIllness })
              }}
            />
            <FormControlLabel
              checked={person.get().isLgbt}
              control={<Checkbox disabled={disableFields} />}
              label={translate("LGBTQIAP+")}
              labelPlacement="end"
              onChange={(event: unknown, isLgbt: boolean) => {
                person.merge({ isLgbt })
              }}
            />
          </FormGroup>
        </Grid>
      </Grid>

    </FormControl>
  );
}