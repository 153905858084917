import React, { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowModel, GridSortModel, GridActionsCellItem, gridClasses } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { Avatar, Button, Chip, CircularProgress, Dialog, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import IListAllDispatch from '../ListAllDispatch/interfaces/IListAllDispatch';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { elapsedTimeWithDate } from '~/utils/dateTime';
import { useHookstate } from '@hookstate/core';
import { Star } from '@mui/icons-material';
import { createState, Downgraded, useState } from '@hookstate/core';
import useSystemState from '~/features/System/stores/SystemState';
import { toSnakeCase } from '~/utils/strings';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { parseDataDispatch } from '~/features/Dispatch/utils/parsers';
import InternalContent from '../Layout/components/InternalContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChangeOperationDialog from '~/components/ChangeOperationDialog';
import ListAdvancedFilters from './components/ListAdvancedFilters';
import IListDispatchSocket from '../ListDispatchesSocket/interfaces/IListDispatchesProps';
import notify from '~/utils/notify';
import { listAdvancedDispatchs } from './components/ListAdvancedFilters/Services';
import userUserState from '~/features/User/stores/UserState';


const rowsState = createState<readonly GridRowModel[]>([]);
const rowCountState = createState(0);
const pageState = createState<any>(0);
const sortState = createState<GridSortModel>([{ field: 'dispatches.id', sort: 'desc' }]);
const loadingState = createState(true);

export const useListAllDispatchState = () => {
  const rows = useState(rowsState);
  rows.attach(Downgraded);
  const rowCount = useState(rowCountState);
  const page = useState<any>(pageState);
  const sort = useState(sortState);
  const loading = useState(loadingState);

  return ({
    rows,
    rowCount,
    page,
    sort,
    loading,
    syncListAllByDispatch: ({ dispatchId, status }) => {

      rows.get().map(({ id }, index) => {
        if (dispatchId == id) {
          rows[index].value.status.set(status.acronym);
          rows[index].value.statusId.set(status.id);
        }
      })
    }
  })
}

// eslint-disable-next-line max-lines-per-function
export default function ListAllDispatchAdvanced({ targetUrl }) {
  const translate = useTranslation().t;
  const { getDispatchById, syncDispatchFromPayload } = useDispatchState();
  const { rows, rowCount, page, sort, loading } = useListAllDispatchState();
  const { verifyPermission } = userUserState();
  const limit = 10;
  const loadingButton = useHookstate<boolean>(false)
  const refresh = useHookstate(false);
  const tickTime = useHookstate(60000);
  const isOpenDialog = useHookstate(false);
  const isOpenOperationDialog = useHookstate(false);
  const { getStatusColors, getPriorityColors } = useSystemState();
  const expandFilter = useHookstate(true);
  const rowSelectedId = useHookstate(null);
  const rowSelectedStatusId = useHookstate(null);
  const rowSelectedToClerk = useHookstate(null)
  const rowOccurrenceId = useHookstate(null)
  const clerkDispatchCodeReference = useHookstate(null)
  const clearState = useHookstate<boolean>(false);

  const labelButton = useHookstate(translate('Search'));
  const expandFilterSearch = useHookstate<boolean>(true);
  const lockFields = useHookstate<boolean>(false);
  const location = useHookstate<string | null>(null);
  const cityId = useHookstate<number | null>(null);
  const groupId = useHookstate<readonly number[] | null>([]);
  const agencyId = useHookstate<number | null>(null);
  const deviceId = useHookstate<number | null>(null);
  const typeId = useHookstate<number | null>(null);
  const subtypeId = useHookstate<number | null>(null);
  const categoryId = useHookstate<number | null>(null);
  const operationId = useHookstate<number | null>(null);
  const withSolvedThirdParty = useHookstate<boolean>(false);
  const code = useHookstate<string | null>(null);
  const statusId = useHookstate<number | null>(null)
  const consummatedAndAttempted = useHookstate<string | null>(null)
  const initialPeriod = useHookstate<string>('')
  const [startDate, setStartDate] = React.useState<string | null>(null);
  const [finishDate, setFinishDate] = React.useState<string | null>(null);
  const onlyToClerk = useHookstate(false)
  const requester = useHookstate<string | null>(null);
  const phone = useHookstate<string | null>(null);
  const dispatchId = useHookstate<number | null>(null);
  const occurrenceNumber = useHookstate<string | null>(null);
  const closedStatusClassId = useHookstate<number | null>(null);
  const closedStatusId = useHookstate<number | null>(null);
  const qualifiersSelected = useHookstate<number[]>([]);
  const groupSetId = useHookstate<number | null>(null);
  const operationUnitId = useHookstate<number | null>(null);

  const dispatches = useHookstate<readonly IListDispatchSocket[]>([]);
  const expandDispatchs = useHookstate<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'menu-appbar' : '';

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuActions = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    rowSelectedId.set(row.id);
    rowSelectedStatusId.set(row.statusId);
    rowSelectedToClerk.set(row.onlyToClerk);
    rowOccurrenceId.set(row.occurrenceId);
    clerkDispatchCodeReference.set(row.code);
  };

  useEffect(() => {
    const intervalToken = setInterval(() => refresh.set(!refresh.get()), tickTime.get());

    return () => {
      clearInterval(intervalToken);
    }
  }, [tickTime.get()]);



  const handleClickAction = (params: GridRenderCellParams<any, any, any>) => {
    isOpenDialog.set(true);

    getDispatchById(params.row.id)
      .then((dispatchData) => {
        const dispatchParsed = parseDataDispatch(dispatchData);
        const { id } = dispatchParsed;

        if (!id) {
          throw new Error('Trying to use a dispatch without Id');
        }

        syncDispatchFromPayload({
          dispatchParams: { dispatch: dispatchParsed },
          canCreateDispatch: true,
          canSaveDispatch: true
        })
          .then(() => {
            isOpenDialog.set(false);
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  }

  const tableMode = "server";
  const columns: GridColDef[] = ([
    { field: 'id' },
    { field: 'occurrence_id', headerName: translate('Nº Occurrence'), sortable: false, width: 115 },
    {
      field: "code",
      headerName: translate('Dispatch Code'),
      flex: 0.7,
      renderCell: (params) => {
        const clickAction = () => {
          handleClickAction(params);
        }
        return <Button type="submit" variant="contained" onClick={clickAction}
          sx={{
            backgroundColor: getStatusColors(params.row.statusId).main,
            color: getStatusColors(params.row.statusId).contrastText,
            '&:hover': {
              backgroundColor: getStatusColors(params.row.statusId).dark,
              color: getStatusColors(params.row.statusId).contrastText,
            },
          }}>
          {params.value}
        </Button>

      },
    },
    { field: 'statusId' },
    { field: 'status', headerName: translate('Status'), flex: 0.4, sortable: false, },
    { field: 'dispatch_group', headerName: translate('Dispatch Group'), flex: 0.7, sortable: false, },
    { field: 'type', headerName: translate('Type'), flex: 1, sortable: false, },
    { field: 'subtype', headerName: translate('Subtype'), flex: 1, sortable: false, },
    { field: 'location', headerName: translate('Location'), width: 500 },
    {
      field: 'priority', headerName: translate('Priority'), flex: 0.4, sortable: false, renderCell: (params) => {
        return <Chip sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
          avatar={
            <Avatar>
              <Brightness1Icon sx={{ color: getPriorityColors(params.row.priority).main }} />
            </Avatar>
          }
          label={params.row.priority} />
      }
    },
    { field: 'elapsed_time', headerName: translate('Elapsed Time'), flex: 0.4, align: 'center', sortable: false },
    {
      field: 'highlight',
      headerName: translate('Highlight'),
      flex: 0.3,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (params.row.highlight ? <Star color="warning" /> : <></>);
      },
    },
    {
      field: 'onlyToClerk', headerName: translate('Clerk'), renderCell: (params) => {
        return params.row.onlyToClerk === 1 ? 'Sim' : 'Não'
      }
    },
    {
      field: 'actions', type: 'actions', headerName: '', flex: 0.2, getActions: ({ id, row }) => [<GridActionsCellItem
        key={id}
        icon={<MoreVertIcon />}
        label='export'
        onClick={(event) => handleMenuActions(event, row)}
      />
      ]
    },
  ]);

  React.useEffect(() => {
    page.set(0);
  }, [targetUrl])

  const printDispatch = () => {
    window.open(`/dispatch/print/${rowSelectedId.get()}`)
  }

  const goToClerkWindow = () => {
    window.open(`/clerk/${rowOccurrenceId.get()}/${clerkDispatchCodeReference.get()}`)
  }

  const disabledSendButton = () => {

    if (occurrenceNumber.get() || dispatchId.get()) {
      return false
    } else if (!startDate || !finishDate) {
      return true
    } else {
      return false
    }

  }


  const requestRowsData = React.useCallback(async (isSubmiting = false) => {

    if (isSubmiting) {
      loadingButton.set(true)
    }

    try {
      const dispatchsData: any = await listAdvancedDispatchs({
        startDate: startDate,
        finishDate: finishDate,
        agencyId: agencyId.get(),
        statusId: statusId.get(),
        groupId: groupId.get(),
        typeId: typeId.get(),
        subtypeId: subtypeId.get(),
        categoryId: categoryId.get(),
        location: location.get(),
        cityId: cityId.get(),
        deviceId: deviceId.get(),
        operationId: operationId.get(),
        withSolvedThirdParty: withSolvedThirdParty.get(),
        page: page.get(),
        limit: limit,
        orders: sort.get(),
        consummated: consummatedAndAttempted.get(),
        onlyToClerk: onlyToClerk.get(),
        requester: requester.get(),
        phone: phone.get(),
        dispatchId: dispatchId.get(),
        occurrenceId: occurrenceNumber.get(),
        closedStatusClassId: closedStatusClassId.get(),
        closedStatusId: closedStatusId.get(),
        qualifiersSelected: qualifiersSelected.get(),
        groupSetId: groupSetId.get(),
        operationUnitId: operationUnitId.get(),
      }).finally(() => {
        loadingButton.set(false)
        loading.set(false);
      })

      if (dispatchsData.rowsCount > 0) {
        rows.set(dispatchsData.data.map((row: IListAllDispatch) => {
          return ({
            id: row.id,
            occurrence_id: row.occurrence.id,
            code: row.code,
            status: row.status?.acronym,
            statusId: row.status?.id,
            dispatch_group: row.dispatch_group ? `${row.dispatch_group.name}` : '-',
            type: row.type ? `[${row.type.code}] ${row.type.name}` : '-',
            subtype: row.subtype ? `[${row.subtype.code}] ${row.subtype.name}` : '-',
            location: row.occurrence?.location,
            priority: row.priority,
            elapsed_time: elapsedTimeWithDate(row.dispatched_at ? row.dispatched_at : new Date()),
            highlight: row.occurrence.featured,
            occurrenceId: row.occurrence.id,
            onlyToClerk: row.occurrence.to_clerk ?? false,
          })
        }))
      } else {
        rows.set([])
      }

      rowCount.set(dispatchsData.rowsCount)

    } catch (error) {
      loading.set(false);
      console.error(error);
    }

  }, [page.attach(Downgraded).value, sort.attach(Downgraded).value, refresh.get(), startDate, finishDate])

  React.useEffect(() => {
    loading.set(true);
    rows.set([]);

    requestRowsData();
  }, [page.attach(Downgraded).value, sort.attach(Downgraded).value, refresh.get()]);




  return (
    <>
      <InternalContent title={`${translate('Filters')}`} sxFont={{ fontWeight: 'bold', fontSize: 18 }}>
        <ListAdvancedFilters
          loadingButton={loadingButton}
          lockFields={lockFields.get()}
          labelButton={labelButton}
          clearFilters={() => {
            location.set('')
            initialPeriod.set('')
            setFinishDate('')
            setStartDate('')
            cityId.set(null)
            deviceId.set(null)
            groupId.set([])
            agencyId.set(null)
            typeId.set(null)
            subtypeId.set(null)
            categoryId.set(null)
            operationId.set(null)
            withSolvedThirdParty.set(false)
            statusId.set(null)
            consummatedAndAttempted.set(null)
            onlyToClerk.set(false)
            requester.set(null)
            phone.set(null)
            dispatchId.set(null)
            code.set('')
            refresh.set(!refresh.get())
            occurrenceNumber.set(null)
            closedStatusClassId.set(null)
            closedStatusId.set(null)
            qualifiersSelected.set([])
            clearState.set(!clearState.get())
            groupSetId.set(null)
            operationUnitId.set(null)
          }}
          disabledSendButton={disabledSendButton}
          // disabledSendButton={!startDate || !finishDate}
          hidden={expandFilterSearch.value}
          location={location}
          cityId={cityId}
          deviceId={deviceId}
          groupId={groupId}
          agencyId={agencyId}
          initialPeriod={initialPeriod}
          categoryId={categoryId}
          typeId={typeId}
          subtypeId={subtypeId}
          operationId={operationId}
          withSolvedThirdParty={withSolvedThirdParty}
          statusId={statusId}
          onlyToClerk={onlyToClerk}
          consummatedAndAttempted={consummatedAndAttempted}
          searchButton={() => {
            requestRowsData(true)
          }}
          onStartDateChange={(value) => {
            labelButton.set(translate('Search'))
            setStartDate(value)
          }}
          onFinishDateChange={(value) => {
            labelButton.set(translate('Search'))
            setFinishDate(value)
          }}
          onPeriodChange={(value) => {
            labelButton.set(translate('Search'))
            initialPeriod.set(value)
          }}
          requester={requester}
          phone={phone}
          dispatchId={dispatchId}
          code={code}
          occurrenceNumber={occurrenceNumber}
          closedStatusClassId={closedStatusClassId}
          closedStatusId={closedStatusId}
          qualifiersSelected={qualifiersSelected}
          clearState={clearState}
          groupSetId={groupSetId}
          operationUnitId={operationUnitId}
        />

      </InternalContent>


      <InternalContent title={`${translate('Dispatches')}`} sxFont={{ fontWeight: 'bold', fontSize: 18 }}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows.get()}
          columns={columns}
          columnVisibilityModel={{
            id: false,
            statusId: false,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: limit } },
          }}
          sx={{
            [`.${gridClasses.columnHeaderTitle}`]: {
              fontWeight: 'bold !important',
            },
          }}
          disableColumnMenu
          onPaginationModelChange={(pageContent) => page.set(pageContent.page)}
          autoHeight
          pagination
          pageSizeOptions={[limit]}
          rowCount={rowCount.get()}
          paginationMode={tableMode}
          filterMode={tableMode}
          sortingMode={tableMode}
          loading={loading.get()}
          onSortModelChange={(onSortChange) => {
            sort.set([])
            onSortChange.map((order) => {
              sort.merge([{
                field: toSnakeCase(order.field),
                sort: order.sort
              }])
            })
          }}
          disableColumnSelector={true}
          slotProps={{
            row: {
              style: { cursor: 'pointer' },
            }
          }}
          getRowId={(row) => Number(row.id)}
        />
        <Menu
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClose}
          sx={{
            marginTop: '30px',
          }}
        >

          {rowSelectedStatusId.get() == 8 && <MenuItem onClick={() => printDispatch()}>
            {translate('Imprimir')}
          </MenuItem>
          }
          {rowSelectedStatusId.get() == 8 && <MenuItem onClick={() => { isOpenOperationDialog.set(true); handleClose() }}>
            {translate('Operação')}
          </MenuItem>
          }
          {rowSelectedStatusId.get() != 8 && Boolean(!rowSelectedToClerk.get()) && <Typography sx={{ margin: 1 }}>{translate('No actions at the moment')}</Typography>
          }
          {
            (verifyPermission([])) && Boolean(rowSelectedToClerk.get()) && (
              <MenuItem onClick={() => goToClerkWindow()}>
                {translate('Clerk mode')}
              </MenuItem>
            )
          }


        </Menu>
      </InternalContent>
      <ChangeOperationDialog open={isOpenOperationDialog} dispatchId={rowSelectedId.get()} refresh={refresh} />
      <Dialog
        open={isOpenDialog.get()}
        PaperProps={{
          sx: { background: 'transparent', display: 'flex', alignItems: 'center', overflow: 'visible' }
        }}
      >
        {isOpenDialog.get() ? <CircularProgress disableShrink size={40} /> : null}
      </Dialog>
    </>
  )
}
