import React, { useEffect } from 'react'
import { Card, CardContent, Chip, Typography, CircularProgress, Button, Grid, Tooltip, Stack, IconButton } from '@mui/material';
import useSystemState from '~/features/System/stores/SystemState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { useHookstate, useState } from "@hookstate/core";
import { getDispatchByIdService } from '~/features/Dispatch/services/index';
import { useMarkerState } from '~/features/Map/stores/MapState';
import { LatLng } from 'leaflet';
import { useLang } from '~/hooks/useLang';
import { useLocation } from 'react-router-dom';
import useSurveillanceReportingState from '~/features/VideoMonitoring/stores/SurveillanceReportingState';
import useUserState from '~/features/User/stores/UserState';
import { convertMsToTimeFriendly, dateTimeFormat } from '~/utils/dateTime';
import Close from '@mui/icons-material/Close';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import useOccurrenceCommentState from '~/features/Occurrence/stores/OccurrenceCommentState';

const elapsedTime = (initialDateTime?: string): string => {
  if (initialDateTime) {
    const dataInicial = new Date(initialDateTime)
    const dataAtual = new Date();
    const tempoDecorrido = dataAtual.getTime() - dataInicial.getTime();
    return convertMsToTimeFriendly(tempoDecorrido);
  }
  else
    return 'N/D';
}

export default function MarkerPopupContentDispatch({ id, status, code, typeSubtype, location, dispatchedAt, closeThisPopUp }) {
  const { getStatusColors } = useSystemState();
  const { dispatch, syncDispatchFromId, dispatchStatusActions } = useDispatchState();
  const { canClosePopup } = dispatchStatusActions();
  const dispatchInfo = dispatch();
  const occurrence = dispatchInfo.occurrence;
  const isAwaiting = useState(false);
  const statusColors = getStatusColors(status);
  const { createMarker, deleteMarker } = useMarkerState();
  const { translate } = useLang();
  const { windowManager } = useSystemState();
  const { surveillanceReportingStates } = useSurveillanceReportingState()
  const { searchDispatches, showCheckbox, searchOccurrence } = surveillanceReportingStates();
  const { verifyPermission } = useUserState();
  const { pathname } = useLocation();
  const isVideoMonitoringPage = pathname === '/video-monitoring';
  const { syncOccurrenceEditAttendent } = useOccurrenceState()
  const { syncPreviousComments } = useOccurrenceCommentState()


  const handleImportDispatch = (dispatchId: number, showOccurrenceWindow = false) => {
    isAwaiting.set(true);
    searchDispatches.set('');
    searchOccurrence.set('');
    syncDispatchFromId(dispatchId).finally(() => {
      isAwaiting.set(false)
      if (showOccurrenceWindow) {
        if (window.location.pathname == '/dispatch/management-map') {
          windowManager().dispatch.open.set(true);
          // syncOccurrenceEditAttendent(occurrence.id.get())
          // syncPreviousComments(occurrence.id.get())
        }
      } else {
        searchDispatches.set(code);
        searchOccurrence.set(occurrence.id.get() as string)
      }
      closeThisPopUp()
    })
  }

  useEffect(() => {
    canClosePopup.get() && closeThisPopUp()
  }, [canClosePopup.get()])

  useEffect(() => {
      getDispatchByIdService(id).then((response) => {
        if (response?.occurrence?.latitude && response?.occurrence?.longitude) {
          const mapDispatch = {
            id,
            position: new LatLng(response?.occurrence?.latitude, response?.occurrence?.longitude),
            location: response?.occurrence?.location ?? '',
            dispatchedAt: response?.dispatched_at,
            code: response?.code,
            status: response?.status.id,
            typeName: response?.type.name,
            subtypeName: response?.subtype?.name ? response?.subtype?.name : null,
            isCurrent: id == dispatch().id.get()
          }

          createMarker({ markerType: 'dispatch', markerIdentifier: id, ...mapDispatch })
        }
      }).catch((error) => {
        if(error == 'Estação não tem acesso à esse despacho'){
          deleteMarker({ markerType: 'dispatch', markerIdentifier: id })
          closeThisPopUp()
        }
      })
    }
  , [id])

  useEffect(() => {
    if(dispatchInfo.id.get() == id && dispatchInfo.status.id.get() != status){
      closeThisPopUp()
    }
  }, [dispatchInfo.id.get(), dispatchInfo.status.id.get()])

  return (
    <Card sx={{ backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none' }}>
      <CardContent sx={{ padding: '1em 0 1em 0', textAlign: "center" }} >
        {code ?
          <Stack direction='row' alignItems='center' gap={1} >
            <Tooltip title={`Despachado em: ${dispatchedAt ? dateTimeFormat(dispatchedAt?.toString()) : ''}`} placement='top'>
              <Chip variant={'outlined'} label={elapsedTime((dispatchedAt ? dispatchedAt?.toString() : ''))} />
            </Tooltip>
            <Chip onClick={() => {
              handleImportDispatch(id, true)
              showCheckbox.set(false)
            }}
              sx={{
                background: statusColors.main,
                color: statusColors.contrastText,
                '&:hover': {
                  background: statusColors.dark,
                },
              }}
              label={isAwaiting.get() ? <CircularProgress sx={{ color: statusColors.contrastText }} size={30} /> : <Typography variant="subtitle1" >{code}</Typography>}
            />
            <IconButton size='small' onClick={() => closeThisPopUp()}>
              <Close fontSize='small'/>
            </IconButton>
          </Stack>
          : false
        }
        <Typography variant="subtitle1" sx={{ margin: '.6em 0' }}>
          {' '}
          {typeSubtype}
        </Typography>
        <Typography variant="subtitle1" sx={{ margin: '.6em 0' }}>
          {' '}
          {location}
        </Typography>

        {isVideoMonitoringPage && <Grid sx={{ display: 'flex', alignContent: 'center', justifyContent: 'space-evenly' }}>
          {
            <Tooltip title={translate('Add Report')}>
              <Chip
                disabled={!code}
                onClick={() => {
                  handleImportDispatch(id)
                  showCheckbox.set(false)
                }}
                label={
                  <Typography variant="subtitle1" >
                    {translate('Add')}
                  </Typography>
                }
              />
            </Tooltip>

          }
          {verifyPermission(['video_surveillance_associate_occurrence']) &&
            <Tooltip title={translate('Associate to Report')}>

              <Chip
                onClick={() => {
                  handleImportDispatch(id)
                  showCheckbox.set(true)
                }}
                label={
                  <Typography variant="subtitle1" >
                    {translate('Associate')}
                  </Typography>}
              />
            </Tooltip>

          }
        </Grid>}
      </CardContent>
    </Card>
  )
}