import { authFetch, isAuthorizedInUrl } from '~/services/fetch'
import { LatLngExpression, LatLng } from 'leaflet'
import {
  parseAccessTokenInUrl,
  parseHostname,
} from '~/features/Map/utils/parsers'
import axios from 'axios'
import type { createDispatchByDeviceProps } from '../types'

export async function storeUserViewPort({
  latitude,
  longitude,
  zoom,
  label,
  favorite,
}) {
  try {
    const response = await authFetch({
      url: '/map-viewport/store',
      method: 'POST',
      data: {
        latitude,
        longitude,
        zoom,
        label,
        favorite,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getMapViewPort() {
  try {
    const response = await authFetch({
      url: '/map-viewport/find-by-user',
      method: 'GET',
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function requestDeviceMarkerUpdate(deviceId) {
  try {
    const response = await authFetch({
      url: '/device/update-map-location-data',
      method: 'POST',
      data: { device_id: deviceId },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getCenterService(): Promise<{
  readonly coordinates: LatLngExpression
  readonly zoom: number
}> {
  try {
    const response = await authFetch({
      url: '/service/get-central-coordinates',
      method: 'POST',
    })

    return response.data
      ? { coordinates: response.data.coordinates, zoom: response.data.zoom }
      : { coordinates: [-4.968465458391433, -39.01640317712328], zoom: 18 }
  } catch (error) {
    throw error
  }
}

export async function getMapUrlService({ darkMode = false }) {
  try {
    const response = await authFetch({
      url: '/service/get-map-url',
      method: 'POST',
      data: { darkMode },
    })

    const validUrl = response.data.token
      ? parseAccessTokenInUrl(response.data.url, response.data.token)
      : response.data.url

    try {
      if (
        await isAuthorizedInUrl({
          url: response.data.statusUrl ?? parseHostname(validUrl),
          timeout: 3000,
        })
      ) {
        return { ...response, url: validUrl }
      } else {
        return (
          await authFetch({
            url: '/service/get-map-url',
            method: 'POST',
            data: { darkMode, fallback: true },
          })
        ).data
      }
    } catch (error) {
      throw error
    }
  } catch (error) {
    throw error
  }
}

export async function getAreaPlanService() {
  try {
    const response = await authFetch({
      url: '/area-plan/areas',
      method: 'GET',
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getCameraService() {
  try {
    const response = await authFetch({
      url: '/camera',
      method: 'GET',
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function createLiveOperation(cameraIndex: string) {
  try {
    const response = await authFetch({
      url: '/camera/show-in-client',
      method: 'POST',
      data: {
        cameraIndex,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getMarkersCameraService() {
  try {
    const { data: camerasPayload } = await authFetch({
      url: '/camera/find-for-map',
      method: 'GET',
    })
    const cameras = Object.keys(camerasPayload).map((cameraIdx) => {
      return {
        id: cameraIdx,
        markerId: `camera-${cameraIdx}`,
        position: new LatLng(
          camerasPayload[cameraIdx].latitude,
          camerasPayload[cameraIdx].longitude
        ),
        cameras: camerasPayload[cameraIdx]['cameras'],
      }
    })

    return cameras
  } catch (error) {
    throw error
  }
}

export async function getMarkersInterestPointService() {
  try {
    const response = await authFetch({
      url: '/interest-point/all-for-map',
      method: 'POST',
      data: {
        only_visible_to_dispatch: window.location.pathname === '/dispatch' ? true : false
      }
    })

    const result = response.data
      .filter((item) => item.latitude != null && item.longitude != null)
      .reduce((acc, interestPoint) => {
        const {
          id,
          /* eslint-disable camelcase */
          interest_point_type,
          interest_point_subtype,
          latitude,
          longitude,
          location,
          validated_at,
          expiration_date,
          revision_date,
          name,
          medias,
          notes,
        } = interestPoint

        // eslint-disable-next-line functional/immutable-data
        acc[id] = {
          id: id,
          markerId: `interest-point-${id}`,
          location: location ?? '',
          position: new LatLng(latitude, longitude),
          typeName: interest_point_type?.name ?? null,
          subtypeName: interest_point_subtype?.name ?? null,
          isCriminal: interest_point_type?.is_criminal,
          isCurrent: false,
          validatedAt: validated_at,
          revisionDate: revision_date,
          expirationDate: expiration_date,
          nameInterestPoint: name,
          medias: medias,
          notes: notes
        }

        return acc
      }, {})

    return result
  } catch (error) {
    throw error
  }
}

export async function createDispatchByDevice({
  deviceId,
  latitude,
  longitude,
  typeId,
  subtypeId,
  comment,
}: createDispatchByDeviceProps) {
  try {
    const response = authFetch({
      url: '/dispatch/create',
      method: 'POST',
      data: {
        device_id: deviceId,
        latitude: latitude,
        longitude: longitude,
        type: typeId,
        subtype: subtypeId,
        comment: comment,
      },
    })
    return response
  } catch (error) {
    throw error
  }
}

export async function getMarkersPrevisionService({start_date, finish_date, city}) {
  try {
    const {data} = await authFetch({
      url: `/occurrence-predictions`,
      method: 'POST',
      data: {
        start_date: start_date,
        finish_date: finish_date,
        // city_ids: [2507507, 2513703, 2501807]
        city_ids: city
      },
    })

    const result = data.filter((item) => item.latitude != null && item.longitude != null && item.total)
    // const result = data.filter((item) => item.latitude != null && item.longitude != null)
      .reduce((acc, prediction) => {
        const {
          latitude,
          longitude,
          total,
        } = prediction;

        // eslint-disable-next-line functional/immutable-data
        acc[`${latitude}${longitude}`] = {
          id: `${latitude}${longitude}`,
          markerId: `prediction-${`${latitude}${longitude}`}`,
          position: new LatLng(
            latitude,
            longitude
          ),
          total: total
        }

        return acc;
      }, {})

    return result
  } catch (error) {
    throw error
  }
}

export async function getMarkersDispatchFilteredPrevisionsService({start_date, finish_date, city}) {
  try {
    const { data } = await authFetch({
      url: '/dispatch/for-prediction-map',
      method: 'POST',
      data: {
        start_date: start_date,
        finish_date: finish_date,
        city_ids: city
      },
    });

    const result = data
      .filter((item) => item.occurrence)
      .filter((item) => item.occurrence.latitude != null && item.occurrence.longitude != null)
      .reduce((acc, dispatch) => {
        const {
          id: dispatchId,
          code,
          occurrence,
          type,
          subtype,
          status,
          /* eslint-disable camelcase */
          dispatched_at
        } = dispatch;

        // eslint-disable-next-line functional/immutable-data
        acc[dispatchId] = {
          id: dispatchId,
          markerId: `dispatch-${dispatchId}`,
          code: code,
          location: occurrence.location ?? '',
          position: new LatLng(
            occurrence.latitude,
            occurrence.longitude
          ),
          typeName: type?.name ?? null,
          subtypeName: subtype?.name ?? null,
          /* eslint-disable camelcase */
          dispatchedAt: dispatched_at ?? null,
          status: status.id,
          isCurrent: false,
        }
        
        return acc;
      }, {})

    return result

  } catch (error) {
    throw error
  }
}

export async function getMarkersDispatchFilteredHistoricalService({dateTime}) {
  try {
    const { data } = await authFetch({
      url: '/dispatch/ongoing-at-the-date',
      method: 'POST',
      data: {
        date_time: dateTime
      },
    });

    const result = data
      .filter((item) => item.occurrence)
      .filter((item) => item.occurrence.latitude != null && item.occurrence.longitude != null)
      .reduce((acc, dispatch) => {
        const {
          id: dispatchId,
          code,
          occurrence,
          type,
          subtype,
          status_at_moment,
          /* eslint-disable camelcase */
          dispatched_at
        } = dispatch;

        // eslint-disable-next-line functional/immutable-data
        acc[dispatchId] = {
          id: dispatchId,
          markerId: `dispatch-${dispatchId}`,
          code: code,
          location: occurrence.location ?? '',
          position: new LatLng(
            occurrence.latitude,
            occurrence.longitude
          ),
          typeName: type?.name ?? null,
          subtypeName: subtype?.name ?? null,
          /* eslint-disable camelcase */
          dispatchedAt: dispatched_at ?? null,
          status: status_at_moment.id,
          isCurrent: false,
        }
        
        return acc;
      }, {})

    return result

  } catch (error) {
    throw error
  }
}