/* eslint-disable max-lines-per-function */
/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect } from 'react';
import { CardActions, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import Layout from '~/components/Layout';
import InternalContent from '~/components/Layout/components/InternalContent';
import InternalHeader from '~/components/Layout/components/InternalHeader';
import { useLang } from '~/hooks/useLang';
import { getAllAreas, sendAreaConfig, sendMultipleAreaConfig } from '../services';
import { useHookstate } from '@hookstate/core';
import SelectBasic from '~/components/SelectBasic';
import ButtonForm from '~/components/ButtonForm';
import LoopIcon from '@mui/icons-material/Loop';
import { useHistory } from 'react-router-dom';
import AreasTreeView, { RenderTree } from '../components/AreasTreeView/';
import { getAllDisptachGroup } from '~/features/DispatchGroup/services';
import { getAllAgencies } from '~/features/Agency/services';
import notify from '~/utils/notify';
import { AreaErrs, loadedObject } from '../interfaces/IAreaConfig';
import FilterAutocompleteDispatchGroup from '~/components/FilterAutocompleteDispatchGroup';
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import FilterAutocompleteTypesAndSubtypesWithDescription, { type typesAndSubtypesSelectedProps } from '~/components/FilterAutocompleteTypesAndSubtypesWithDescription';

export interface typesFormatedForSendingProps {
  readonly type_id: number;
  readonly subtype_id: number | null;
}

export default function AreaConfigCreate() {
  const { translate } = useLang();
  const history = useHistory();
  const processing = useHookstate<number>(0);
  const hideGroups = useHookstate(false);

  const areaId = useHookstate<string | null>(null);
  const areasOptions = useHookstate<Array<loadedObject | null>>([]);

  const disptachGroupId = useHookstate<number | null>(null);

  const agencyId = useHookstate<number | null>(null);
  const typeId = useHookstate<number | null>(null);
  const subtypeId = useHookstate<number[] | null>(null);
  const typesAndSubtypesSelected = useHookstate<typesAndSubtypesSelectedProps[] | []>([]);
  const typesFormatedForSending = useHookstate<typesFormatedForSendingProps[] | []>([]);
  const requiredSubType = useHookstate(false);

  const erros = useHookstate<AreaErrs>({
    agency: '',
    area: '',
    dispatchGroup: '',
    type: '',
    subType: ''
  });

  const handleCancelButton = () => {
    history.push('/area-config');
  }

  const clearFilters = () => {
    agencyId.set(null)
    disptachGroupId.set(null)
    areaId.set(null)
    typeId.set(null)
    subtypeId.set(null)
  }

  const checkRequired = () => {
    const type = typesAndSubtypesSelected.get().map((type) => type)
    const validations = {
      agency: {
        condition: () => !agencyId.get(),
        message: 'Inform a agency',
      },
      dispatchGroup: {
        condition: () => !hideGroups.get() && !disptachGroupId.get(),
        message: 'Inform a dispatch group',
      },
      area: {
        condition: () => !hideGroups.get() && !areaId.get(),
        message: 'Inform a area',
      },
      type: {
        condition: () => type.length == 0,
        message: 'Inform a type',
      },
      subType: {
        condition: () => requiredSubType.get(),
        message: 'Inform a subtype',
      },
    };

    const newErrors = {};
    for (const [key, { condition, message }] of Object.entries(validations)) {

      newErrors[key] = condition() ? `${translate(message)}` : '';
      erros.merge({ ...erros.get(), ...newErrors });
    }

    return false;
  };



  const createObjectForSend = () => {
    typesAndSubtypesSelected.get().map((type) => {
      if (type.subtype?.length) {
        type.subtype.filter((sub) => sub?.typeId === type.id).map((item) => {
          typesFormatedForSending.merge([{
            type_id: type.id,
            subtype_id: item.id ? item.id : null
          }])
        })
      } else {
        typesFormatedForSending.merge([{
          type_id: type.id,
          subtype_id: null
        }])
      }
    })
  }

  const handleSubmit = () => {
    if (checkRequired()) {
      return
    }

    createObjectForSend()

    if (hideGroups.get()) {
      sendMultipleAreaConfig({
        agencyId: agencyId.get(),
        configTypes: typesFormatedForSending.get()
      }).then((response) => {
        if (response.status === 200) {
          notify({ message: translate('Area configured successfully'), type: 'success' })
          setTimeout(() => {
            handleCancelButton()
          }, 1500)
        }
      })
    } else {
      typesFormatedForSending.get()?.forEach(item => {
        return (
          sendAreaConfig({
            agencyId: agencyId.get(),
            areaId: areaId.get(),
            dispatchGroupId: disptachGroupId.get(),
            typeId: String(item.type_id),
            subtypeId: item.subtype_id ? String(item.subtype_id) : null
          }).then((response) => {
            if (response.status === 200) {
              notify({ message: translate('Area configured successfully'), type: 'success' })
              setTimeout(() => {
                handleCancelButton()
              }, 1500)
            }
          }).finally(() => {
            typesFormatedForSending.set([])
          })
        )
      })
    }
  }

  useEffect(() => {
    getAllAreas().then(areas => {
      areasOptions.set(areas.map(areaMap => ({ id: areaMap.id, name: areaMap.name })))
    })
  }, [])


  return (
    <Layout marginLayout={true}>
      <InternalHeader title={translate('Area Configuration')} />
      <InternalContent expandButton={false}>

        <Grid xs={12} container direction={'row'} rowGap={2}>
          <Grid item xs={3} >
              <FilterAutocompleteAgency
                agencyId={agencyId.get()}
                onAgencyChange={(agency) => {
                  agencyId.set(agency);
                  disptachGroupId.set(null)
                  erros.set({ ...erros.get(), agency: '' })
                }}
                error={erros.agency}
              />
            </Grid>
          <Grid item xs={4} paddingLeft={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={hideGroups.get()}
                  onChange={() => {
                    hideGroups.set(!hideGroups.get())
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                  defaultChecked />
              }
              label={translate('Link by Agencies')} />
          </Grid>

          <Grid container xs={12}>
            {!hideGroups.get() && (
              <>
                <Grid item xs={3}>
                  <FilterAutocompleteDispatchGroup
                    agencyId={agencyId.get() ?? undefined}
                    dispatchGroupId={disptachGroupId.get() ?? undefined}
                    onGroupDispatchChange={(disptachGroupValue) => {
                      disptachGroupId.set(disptachGroupValue);
                      erros.set({ ...erros.get(), dispatchGroup: '' })
                    }}
                    error={erros.dispatchGroup}
                  />
                </Grid>

                <Grid item xs={3} paddingLeft={1}>
                  <SelectBasic
                    state={areaId}
                    options={areasOptions.get()}
                    placeholder={translate('Area')}
                    disabled={false}
                    error={erros.area}
                    size={'small'} />
                </Grid>
              </>)}
          </Grid>


          <Grid item xs={12}>
            <FilterAutocompleteTypesAndSubtypesWithDescription
              typeId={typeId}
              subtypeIds={subtypeId}
              lockFields={false}
              typesAndSubtypesSelected={typesAndSubtypesSelected}
              errorType={erros.type}
              errorSubType={erros.subType}
              requiredSubType={requiredSubType}
            />
          </Grid>
        </Grid>
        <CardActions sx={{ pt: 4, display: "flex", alignItems: 'center', justifyContent: 'flex-end' }} >
          <ButtonForm
            type='cancel'
            onClick={clearFilters}>
            {translate('Clear Filters')}
          </ButtonForm>
          <ButtonForm
            type='cancel'
            onClick={handleCancelButton}>
            {translate('Cancel')}
          </ButtonForm>
          <ButtonForm
            onClick={handleSubmit}
            type='submit'>
            <LoopIcon className='load-icon' sx={{ display: processing.get() ? 'block' : 'none' }} />
            {processing.get() ? translate('Saving') : translate('Save')}
          </ButtonForm>
        </CardActions>
      </InternalContent>
    </Layout >
  )
}