/* eslint-disable max-lines-per-function */
import React from 'react';
import Button from '@mui/material/Button';
import FormPeopleAddress from '../FormPeopleAddress';
import FormPeoplePersonalData from '../FormPeoplePersonalData';
import FormPeopleOcurrenceData from '../FormPeopleOcurrenceData';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationPersonState from '~/features/Dispatch/stores/DispatchRegistrationPersonState';
import useDispatchRegistrationState from '~/features/Dispatch/stores/DispatchRegistrationState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { Card, CardActions, CardContent, Divider } from '@mui/material';
import { Downgraded, State } from '@hookstate/core';
import { LoadingButton } from '@mui/lab';
import useSystemState from '~/features/System/stores/SystemState';

interface IFormPeople {
  readonly loading: State<boolean>
  readonly handleSave: () => void;
  readonly handleEdit: () => void;
}

export default function FormPeople({ handleSave, handleEdit, loading }: IFormPeople) {
  const { translate } = useLang();
  const { systemConfig } = useSystemState();

  const { dispatch } = useDispatchState();
  const { status } = dispatch();

  const {
    registrationPersonDispatch,
  } = useDispatchRegistrationPersonState();
  const { person } = registrationPersonDispatch();

  const {
    registrationDispatch,
  } = useDispatchRegistrationState();

  const {
    registrationRowId,
    actionName,
    openConfirmAction,
    inputErrors,
  } = registrationDispatch();

  const someInvalidInput = () => {
    if (!person.get().gender) {
      inputErrors.gender.set(true)
    }
    if (!person.get().involvementType) {
      inputErrors.involvementType.set(true)
    }
    if (!person.get().arrested) {
      inputErrors.arrested.set(true)
    }
    if (!person.get().peopleCircunstance) {
      inputErrors.peopleCircunstance.set(true)
    }
    if (!person.get().destination) {
      inputErrors.destination.set(true)
    }
    if (!person.get().publicAgent) {
      inputErrors.publicAgent.set(true)
    }
    if (person.get().publicAgent && !person.organization) {
      inputErrors.organization.set(true)
    }
    if (!person.get().occupation) {
      Boolean(parseInt(systemConfig().requireOccupationOnAssociatedPeople.get())) ? inputErrors.occupation.set(true) : inputErrors.occupation.set(false)
    }

    return Object.values(inputErrors.attach(Downgraded).get()).some(value => !!value)
  }


  const handleNext = async () => {
    if (someInvalidInput()) {
      return
    }
    handleSave();
    return
  };

  return (
    <Card
      onKeyDown={(event) => {
        if (!registrationRowId.get().length && event.code === "Enter" && event.ctrlKey) {
          handleNext()
        } else if (!!registrationRowId.get().length && event.code === "Enter" && event.ctrlKey) {
          handleEdit()
        }
      }}>
      <CardContent sx={{ maxHeight: 500, overflow: 'auto' }}>
        <Divider sx={{ mb: 2 }}>
          {translate('Occurrence Data')}
        </Divider>
        <FormPeopleOcurrenceData disableFields={status.value.id === 8} />
        <Divider sx={{ mt: 2, mb: 2 }}>
          {translate('Personal Data')}
        </Divider>
        <FormPeoplePersonalData disableFields={status.value.id === 8} />
        <Divider sx={{ mb: 2 }}>
          {translate('Address')}
        </Divider>
        <FormPeopleAddress disableFields={status.value.id === 8} />
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {!!person.get().id &&
          (<LoadingButton
            loading={loading.get()}
            disabled={status.value?.id === 8}
            onClick={handleEdit}
            color="secondary"
            sx={{
              mr: 1,
            }}
          >
            {translate('Update')}
          </LoadingButton>)
        }

        <Button
          sx={{ marginRight: '8px' }}
          variant="outlined"
          onClick={
            () => {
              actionName.set('cancel')
              openConfirmAction.set(true);
            }
          }
        >
          {translate("Cancel")}
        </Button>

        {!person.get().id && (
          <LoadingButton
            loading={loading.get()}
            disabled={status.value?.id === 8}
            variant="contained"
            onClick={handleNext}
            sx={{ mr: 1 }}
          >
            {translate('Save')}
          </LoadingButton>)
        }
      </CardActions>
    </Card >
  );
}