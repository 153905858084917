import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getSpecies } from './service';
import { SpecieResponse } from './interface';

interface FilterAutocompleteSpecieProps {
    readonly onSpecieChange: (value: number | null) => void;
    readonly specieId?: number | null
    readonly disabled?: boolean
}

export default function FilterAutocompleteSpecie({ disabled, onSpecieChange, specieId }: FilterAutocompleteSpecieProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly SpecieResponse[]>([]);
    const [optionsFilter, setOptionsFilter] = useState<readonly SpecieResponse[]>([]);
    const [value, setValue] = useState<SpecieResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getSpecies().then(species => {
            setOptions(species)
            setOptionsFilter(species)
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (!specieId) {
            setValue(null)
        }
    }, [specieId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-species'
                options={optionsFilter}
                disabled={disabled}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onSpecieChange(type?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Specie')}
                    />
                )}
            />
        </FormControl>
    )
}