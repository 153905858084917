/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { Button, Card, CardHeader, Collapse, Grid, IconButton } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '~/components/ErrorFallback';
import Layout from '~/components/Layout';
import { useHookstate } from '@hookstate/core';
import { useLang } from '~/hooks/useLang';
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod';
import FilterAutocompleteDevice from '~/components/FilterAutocompleteDevice';
import { downloadReportCSV, makeRequestToGetVehiclesInOccurrence } from '../../services/report';
import useReportState from '../../stores/ReportState';
import notify from '~/utils/notify';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GenerateReportButton from '../../components/GenerateReportButton';
import FilterSwitchOtherDispatchsGroup from '~/components/FilterSwitchOtherDispatchsGroups';
import ExportButtonCSV from '../../components/ButtonExportCSV';
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies';
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups';
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity';
import FilterAutocompleteEntryOrigin from '~/components/FilterAutocompleteEntryOrigin';
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet';
import FilterAutocompleteTypesAndSubtypesWithDescription, { type typesAndSubtypesSelectedProps } from '~/components/FilterAutocompleteTypesAndSubtypesWithDescription';
import { formatTypeAndSubtypeForReport } from '~/utils/format';

export default function SearchEmploymentVehiclesOccurrences() {
  const { reportData, loadingReport } = useReportState();
  const { translate } = useLang();
  const expandFilterSearch = useHookstate<boolean>(true);
  const lockFields = useHookstate<boolean>(false);
  const groupIds = useHookstate<readonly number[]>([]);
  const agencyIds = useHookstate<readonly number[]>([]);
  const deviceId = useHookstate<number | null>(null);
  const startDate = useHookstate<string | null>(null);
  const finishDate = useHookstate<string | null>(null);
  const labelButton = useHookstate<string | null>(null)
  const withSolvedThirdParty = useHookstate<boolean>(false)
  const period = useHookstate('')
  const isLoading = useHookstate(false);
  const reportName = 'employment-vehicles-occurrences'
  const cityId = useHookstate<number | null>(null);
  const entryOriginId = useHookstate<number | null>(null);
  const subtypesIds = useHookstate<number[]>([]);
  const groupSetId = useHookstate<number | null>(null)
  const typeId = useHookstate<number | null>(null);
  const subtypeId = useHookstate<number[] | null>(null);
  const typesAndSubtypesSelected = useHookstate<typesAndSubtypesSelectedProps[] | []>([]);
  const typesFormatedForSending = useHookstate<number[]>([]);
  const subTypesFormatedForSending = useHookstate<number[]>([]);

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  async function generateReport(exportCSV = false) {
    if (startDate.get() && finishDate.get()) {

      formatTypeAndSubtypeForReport({
        typesAndSubtypesSelected: typesAndSubtypesSelected.get(),
        typesFormatedForSending: typesFormatedForSending,
        subTypesFormatedForSending: subTypesFormatedForSending
      })
      isLoading.set(true)
      loadingReport().set(true)

      const data = await makeRequestToGetVehiclesInOccurrence({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        deviceId: deviceId.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsIds: groupIds.get(),
        withSolvedThirdParty: withSolvedThirdParty.get(),
        cityId: cityId.get(),
        entryOriginId: entryOriginId.get(),
        typeIds: typesFormatedForSending.get(),
        subtypeIds: subTypesFormatedForSending.get(),
        groupSetId: groupSetId.get(),
        exportCSV: exportCSV,
      }).finally(() => {
        loadingReport().set(false)
        isLoading.set(false)
      })

      if (exportCSV) {
        downloadReportCSV(`${translate(reportName)}-${new Date().getTime()}.csv`, data)
      } else {
        reportData().set(data)
        window.open(`/report/result/${reportName}`)
      }
    } else {
      notify({ message: translate('Initial and final date are mandatory'), type: 'error' })
    }
  }

  const handleClearFilter = () => {
    deviceId.set(null)
    agencyIds.set([])
    groupSetId.set(null)
    groupIds.set([])
    entryOriginId.set(null)
    cityId.set(null)
    period.set('')
    typeId.set(null)
    subtypesIds.set([])
    withSolvedThirdParty.set(false)
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true} >
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader title={`${translate('Report')}: ${translate(`Occurrences by device`)}`} />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Filters')}`}
            avatar={(
              <IconButton
                onClick={() => expandFilterSearch.set(!expandFilterSearch.get())}
                sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
                {expandFilterSearch.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
              </IconButton>
            )}
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDevice
                    disabled={lockFields.get()}
                    groupDispatchs={groupIds.get() ?? undefined}
                    onDeviceChange={(device) => {
                      deviceId.set(device)
                    }}
                    deviceId={deviceId.get()} />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    disabled={lockFields.get()}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => {
                      labelButton.set(translate('Search'))
                      agencyIds.set(agency)
                    }} />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchGroupSet
                    disabled={lockFields.get()}
                    dispatchGroupSetId={groupSetId?.get() ?? undefined}
                    onGroupDispatchSetChange={(group) => {
                      groupSetId?.set(group)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get()}
                    dispatcheGroups={groupIds.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupIds.set(group)
                      deviceId.set(null)
                      labelButton.set(translate('Search'))
                    }}
                    groupSetId={groupSetId.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteCity
                    disabled={lockFields.get()}
                    onCityChange={(city) => {
                      cityId?.set(city)
                      labelButton.set(translate('Search'))
                    }}
                    cityId={cityId?.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteEntryOrigin
                    disabled={lockFields.get()}
                    onEntryOriginChange={(entryOrigin) => {
                      entryOriginId?.set(entryOrigin)
                      labelButton.set(translate('Search'))
                    }}
                    originId={entryOriginId?.get()}
                  />
                </Grid>
                <Grid container direction={'row'}>
                  <FilterSelectPeriod
                    disabled={lockFields.get()}
                    onStartDateChange={(value) => startDate.set(value?.toJSON() ?? null)}
                    onFinishDateChange={(value) => finishDate.set(value?.toJSON() ?? null)}
                    onPeriodChange={(value) => { period.set(value) }}
                    period={period.get()}
                    required
                  />
                  <Grid item xs={5} sx={{ pl: 2, pt: 1 }}>
                    <FilterSwitchOtherDispatchsGroup
                      onCheckedChange={() => {
                        labelButton.set(translate('Search'))
                        withSolvedThirdParty.set(!withSolvedThirdParty.value)
                      }}
                      disabled={lockFields.get()}
                      checked={withSolvedThirdParty.get()}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={9} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteTypesAndSubtypesWithDescription
                    typeId={typeId}
                    subtypeIds={subtypeId}
                    lockFields={false}
                    typesAndSubtypesSelected={typesAndSubtypesSelected}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2,
                  pt: 2
                }}>
                <Button variant="outlined"
                  onClick={() => handleClearFilter}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={() => generateReport(true)}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={generateReport}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary >
  )
}