import React from "react";
import { IconButton, Grid } from '@mui/material';
import TliaButton from '~/features/Entry/components/TliaButton';
import DispatchButton from '~/features/Entry/components/DispatchButton';
import ListHistoryButton from '../ListHistoryButton';
import PreviousNavegationButton from '../PreviousNavegationButton';
import SaveButton from '../SaveButton';
import SetDispatchButton from '~/features/Entry/components/EntrySetDispatchGroupButton';
import CreateButton from '../CreateButton';
import SearchAdvancedButton from '../SearchAdvancedButton';
import NextNavegationButton from '../NextNavegationButton';
import RecallButton from '~/features/Entry/components/RecallButton';
import TransferGroupDispachButton from '~/features/Dispatch/components/TransferGroupDispachButton';
import { SearchBoxButton } from "../SearchBoxButton";
import userUserState from '~/features/User/stores/UserState';
import { Print } from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';
import ListDispatchesButton from "../ListDispatchesButton";
import ListVehiclesButton from "../ListVehiclesButton";
import { EditOff } from '@mui/icons-material';
import { AxiosResponse } from "axios";
import AddClerk from "../AddClerk";
import EntryCreateClosedOccurrenceButton from "~/features/Entry/components/EntryCreateClosedOccurrenceButton";
import DispatchShowProcedureButton from "~/features/Dispatch/components/DispatchShowProcedureButton";
import useDispatchState from "~/features/Dispatch/stores/DispatchState";

interface ButtonActionsContainerProps {
  readonly isValid: boolean;
  readonly readonly?: boolean;

  readonly hasHistory?: boolean;
  readonly historyUrl?: string;

  readonly listFullDispatchUrl?: string;
  readonly listFullVehiclesUrl?: string;

  readonly hasPrevious?: boolean;
  readonly previous?: VoidFunction;

  readonly hasNext?: boolean;
  readonly next?: VoidFunction;

  readonly canCreate?: boolean;
  readonly create?: VoidFunction;

  readonly canSave?: boolean;
  readonly canSeeFullList?: boolean;
  readonly save?: VoidFunction;

  readonly cancelEdit?: VoidFunction;

  readonly canTlia?: boolean;
  readonly tlia?: (params) => Promise<void>;

  readonly canRecall?: boolean;
  readonly recall?: (params) => Promise<AxiosResponse>;
  readonly hasLatLng?: boolean;
  readonly canDispatch?: boolean;
  readonly dispatch?: () => Promise<void>;
  readonly canSearch?: boolean;
  readonly canEntryOpenCloseOcccurrence?: boolean
  readonly entryOpenCloseOccurrence?: (params: any) => Promise<AxiosResponse>;

  readonly canSearchAdvanced?: boolean;
  readonly searchAdvancedUrl?: string;

  readonly canClerk?: boolean | null;
  readonly clerk?: boolean;

  readonly canPrint?: boolean;
  readonly print?: VoidFunction;

  readonly canShowDispatchProcedure?: boolean;
  readonly entryOpenCloseOccurrenceDisabled?: boolean;
}

export default function ButtonActionsContainer({ readonly = false, ...props }: ButtonActionsContainerProps) {
  const { translate } = useLang()
  const { verifyPermission } = userUserState();
  const dispatchState = useDispatchState()
  const loadingDispatchForm = dispatchState.loadingDispatchForm()

  return (
    <Grid container spacing={1}>
      {(verifyPermission(['entry_index', 'dispatch_window_support'])) && (
        props.historyUrl ? <ListHistoryButton hasHistory={props.hasHistory} historyUrl={props.historyUrl} /> : false
      )}

      {props.listFullDispatchUrl && props.canSeeFullList && <ListDispatchesButton listFullDispatchUrl={props.listFullDispatchUrl} />}

      {props.listFullVehiclesUrl && props.canSeeFullList && <ListVehiclesButton hasHistory={props.hasHistory} historyUrl={props.listFullVehiclesUrl} />}

      {props.previous ? <PreviousNavegationButton hasPrevious={props.hasPrevious} previous={props.previous} loadingDispatchForm={loadingDispatchForm.get()} /> : false}

      {props.next ? <NextNavegationButton hasNext={props.hasNext} next={props.next} loadingDispatchForm={loadingDispatchForm.get()} /> : false}

      {(verifyPermission([])) && (
        props.canSearch ? <SearchBoxButton /> : false
      )}

      {(verifyPermission([])) && (
        props.canSearchAdvanced ? <SearchAdvancedButton canSearchAdvanced={props.canSearchAdvanced} searchAdvancedUrl={props.searchAdvancedUrl} /> : false
      )}

      {(verifyPermission(['entry_create'])) && (
        props.create ? <CreateButton canCreate={props.canCreate && !readonly} create={props.create} /> : false
      )}

      {(verifyPermission(['entry_to_tlia'])) && (
        props.tlia ? <TliaButton canTlia={props.canTlia && !readonly} tlia={props.tlia} /> : false
      )}

      {(verifyPermission(['entry_to_recall'])) && (
        props.recall ? <RecallButton canRecall={props.canRecall && !readonly} recall={props.recall} create={props.create} /> : false
      )}

      {(verifyPermission(['entry_to_dispatch'])) && (
        props.dispatch ? <DispatchButton hasLatLng={Boolean(props.hasLatLng)} canDispatch={props.canDispatch && !readonly} dispatch={props.dispatch} /> : false
      )}

      {(verifyPermission(['entry_create_closed_occurrence'])) && (
        props.entryOpenCloseOccurrence ?
          <EntryCreateClosedOccurrenceButton
            hasLatLng={Boolean(props.hasLatLng)}
            entryOpenCloseOccurrence={props.entryOpenCloseOccurrence}
            canEntryCloseOccurrence={!props.entryOpenCloseOccurrenceDisabled}
          /> : false
      )}


      {(verifyPermission(['dispatch_print'])) && (
        props.print ? (
          <Grid item>
            <IconButton title={translate('Print')} disabled={!props.canPrint} onClick={() => props.print ? props.print() : false}>
              <Print />
            </IconButton>
          </Grid>
        ) : false
      )}
      {(verifyPermission(['occurrence_set_clerk'])) && (
        props.clerk ? (<AddClerk canClerk={props.canClerk} />) : false
      )}

      {
        props.canShowDispatchProcedure && (
          <DispatchShowProcedureButton />
        )
      }

      {
        (verifyPermission([])) && (
          props.save ? <SaveButton canSave={props.canSave && props.isValid && !readonly} save={props.save} /> : false
        )
      }

      {(verifyPermission(['dispatch_index'])) && (
        props.save && props.canSave && props.isValid && props.cancelEdit ? (
          <Grid item>
            <IconButton title={translate('Cancel Edit')} onClick={() => props.cancelEdit ? props.cancelEdit() : false}>
              <EditOff />
            </IconButton>
          </Grid>
        ) : false
      )}

    </Grid>
  )
}
