import React from "react";
import {IconButton, Grid} from '@mui/material';
import {ArrowForwardIos} from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';

interface NextNavegationButtonProps {
  readonly hasNext?: boolean;
  readonly next?: VoidFunction;
  readonly loadingDispatchForm?: boolean;
}


export default function NextNavegationButton({ hasNext, next, loadingDispatchForm }: NextNavegationButtonProps) {
  const { translate } = useLang()
 
  return (
    <Grid item>
      <IconButton title={translate('Next')} disabled={!hasNext || loadingDispatchForm} onClick={() => next ? next() : false}>
        <ArrowForwardIos/>
      </IconButton>
    </Grid>);

}