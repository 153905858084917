import React from 'react';
import ICrudGeneratorFields from './interfaces/ICrudGeneratorFields'
import CrudTextField from './components/CrudTextField';
import CrudCheckBoxField from './components/CrudCheckBoxField';
import CrudAutoCompleteField from './components/CrudSelectAutoCompleteField';
import CrudSelectField from './components/CrudSelectField';
import CrudSliderField from './components/CrudSliderField';
import CrudListTransferField from './components/CrudListTransferField';
import CrudDateField from './components/CrudDateField';
import CrudDateTimeField from './components/CrudDateTimeField';
import CrudTreeView from './components/CrudTreeView';
import CrudUploadField from './components/CrudUploadField';
import CrudSwitchField from './components/CrudSwitchField';
import CrudAutoCompleteAgregateField from './components/CrudSelectAutoCompleteAgregateField';
import CrudNumberField from './components/CrudNumberField';
import CrudSelectAutoCompleteGrouped from './components/CrudSelectAutoCompleteGrouped';
import CrudFetchAutocomplete from './components/CrudFetchAutoComplete';
import CrudLongTextField from './components/CrudLongTextField';
import { UnpackNestedValue } from 'react-hook-form';
import CrudTableField from './components/CrudTableField';


export default function CrudGeneratorFields({ fields, register, control, getValues, setFormValue }: ICrudGeneratorFields) {
  function generateField(param, index) {
    if (param.type == 'string' && param.mask) {
      return <CrudTextField key={index} getValues={ getValues } register={register} model={param.name} control={control} mask={param.mask} placeholder={param.placeholder} required={param.required} readonly={param.readonly}/>
    }else if (param.type == 'string' && param.placeholder) {
      return <CrudTextField key={index} getValues={ getValues } register={register} model={param.name} placeholder={param.placeholder} required={param.required} readonly={param.readonly}/>
    }else if (param.type == 'string') {
      return <CrudTextField key={index} getValues={ getValues } register={register} model={param.name} placeholder={param.placeholder} required={param.required} readonly={param.readonly}/>
    }else if (param.type == 'password') {
      return <CrudTextField key={index} getValues={ getValues } register={register} model={param.name} passwd required={param.required} />
    }else if (param.type == 'number') {
      return <CrudNumberField key={index} getValues={ getValues } register={register} model={param.name} min={param.min} max={param.max} placeholder={param.placeholder}/>
    } else if (param.type === 'upload') {
      return <CrudUploadField model={param.name} dropZoneOptions={param.dropZoneOptions ? param.dropZoneOptions : undefined} useForm={ () => { return { control,register,setFormValue,getValues } } } />
    } else if (param.type == 'boolean') {
      return <CrudCheckBoxField key={index} getValues={ getValues } register={register} model={param.name} defaultChecked={param.defaultChecked} control={control} placeholder={param.placeholder} />
    } else if (param.type == 'switch') {
      return <CrudSwitchField key={index} getValues={ getValues } register={register} model={param.name} checked={param.checked} control={control} placeholder={param.placeholder} setFormValue={setFormValue}/>
    } else if (param.type == 'selectAutoComplete') {
      return <CrudAutoCompleteField method={param.method} key={index} register={register} name={param.name} model={param.model} control={control} placeholder={param.placeholder} optionSelect={param.optionSelect} getValues={getValues} setFormValue={setFormValue} required={param.required} filter={param.filter} independent={param.independent} filterName={param.filterName} dependent={param.dependent}/>
    } else if (param.type == 'fetchtAutoComplete') {
      return <CrudFetchAutocomplete method={param.method} key={index} register={register} name={param.name} model={param.model} urlFetch={param.urlFetch} control={control} placeholder={param.placeholder} optionSelect={param.optionSelect} getValues={getValues} setFormValue={setFormValue} defaultFetch={param.defaultChecked}/>
    } else if (param.type == 'selectAutoCompleteGrouped') {
      return <CrudSelectAutoCompleteGrouped method={param.method} key={index} register={register} name={param.name}  model={param.model} control={control} placeholder={param.placeholder} optionSelect={param.optionSelect} getValues={getValues} setFormValue={setFormValue} />
    } else if (param.type == 'selectAutoCompleteAgregate') {
      return <CrudAutoCompleteAgregateField method={param.method} key={index} register={register} setFormValue={setFormValue} name={param.name} namePrimary={param.namePrimary} nameSecondary={param.nameSecondary} modelPrimary={param.modelPrimary} modelSecondary={param.modelSecondary} control={control} placeholderSecondary={param.placeholderSecondary} placeholderPrimary={param.placeholderPrimary} optionSelect={param.optionSelect} requiredPrimary={param.requiredPrimary} requiredSecondary={param.requiredSecondary}/>
    } else if (param.type == 'select') {
      return <CrudSelectField key={index} register={register} name={param.name} control={control} options={param.options} placeholder={param.placeholder} setFormValue={setFormValue} />
    } else if (param.type == 'slider'){
      return <CrudSliderField key={index} getValues={ getValues } register={register} model={param.name} control={control} minimum={param.minimum} maximum={param.maximum}/>
    } else if (param.type == 'date'){
      return <CrudDateField key={index} getValues={ getValues } register={register} model={param.name} control={control} setFormValue={setFormValue} placeholder={param.placeholder}/>
    } else if (param.type == 'datetime'){
      return <CrudDateTimeField key={index} getValues={ getValues } register={register} model={param.name} control={control} setFormValue={setFormValue} placeholder={param.placeholder}/>
    } else if (param.type == 'transferList') {
      return <CrudListTransferField key={index} getValues={getValues} register={register} model={param.name} options={param.options} placeholder={param.placeholder} setFormValue={setFormValue} showColumnText={param.showColumnText ?? 'name'} />
    } else if (param.type == 'checkTreeView') {
      return <CrudTreeView key={index} getValues={ getValues } register={register} model={param.name} options={param.options} placeholder={param.placeholder} setFormValue={setFormValue}/>
    } else if (param.type == 'longtext') {
      return <CrudLongTextField key={index} getValues={ getValues } register={register} model={param.name} control={control} mask={param.mask} placeholder={param.placeholder} required={param.required} readonly={param.readonly} setFormValue={setFormValue}/>
    }else if (param.type == 'table') {
      return <CrudTableField key={index} getValues={ getValues } register={register} model={param.name} control={control} placeholder={param.placeholder} columns={param.columns} lines={param.lines} filterModel={param.filterModel} rows={param.rows} required={param.required} validate={param.validate} columnGroup={param.columnGroup}/>
    }
  }

  return (<>
    {fields.map(generateField)}
  </>)
}