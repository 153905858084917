/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CrudIndex from '~/components/CrudIndex';
import { ActionButtonsProps } from '~/components/CrudIndex/interfaces/ICrudIndex';
import { useLang } from '~/hooks/useLang';
import useUserState from '../stores/UserState';
import { SyncLockRounded } from '@mui/icons-material';
import Layout from '~/components/Layout';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { authFetch } from '~/services/fetch';
import ConfirmDialog from '~/components/ConfirmDialog';
import { useHookstate } from '@hookstate/core';
import notify from '~/utils/notify';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';

export default function Index() {
  const history = useHistory();
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const openDialogConfirmDisconnect = useHookstate(false);
  const openDialogConfirmDisabled = useHookstate(false);
  const userActionId = useHookstate(0)
  const isEnabled = useHookstate(false)
  const userName = useHookstate('')
  const loading = useHookstate(false)
  const agencyId = useHookstate<number|null>(null)
  const forceRefresh = useHookstate(false)
  const lockFields = useHookstate<boolean>(false);
  const active = useHookstate<boolean|null>(null);
  const user = useHookstate<string|null>(null);
  const permissionGroupId = useHookstate<number|null>(null)

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])

  const handleDialogConfirmDisconnect = () => {
    openDialogConfirmDisconnect.set(false)
  };

  const handleDialogConfirmOpenDisconnect = (userId) => {
    openDialogConfirmDisconnect.set(true)
    userActionId.set(userId)
  };


  const handleDialogConfirmDisabled = () => {
    openDialogConfirmDisabled.set(false)
  };

  const handleDialogConfirmOpenDisabled = (userId) => {
    openDialogConfirmDisabled.set(true)
    userActionId.set(userId)
  };

  const disconnect = (userId) => {
    loading.set(true)
    authFetch({
      url: '/user/force-disconnection',
      method: 'POST',
      data: {
        user_id: userId
      }
    }).then((response) => {
      if (response.status === 200) {
        openDialogConfirmDisconnect.set(false)
        notify({ message: translate('User logged out successfully'), type: 'success' })
      }
    }).finally(() => {
      loading.set(false)
    })
  }

  const disabledUser = (userId) => {
    loading.set(true)
    authFetch({
      url: `/user/${userId}/activate-or-deactivate`,
      method: 'POST'
    }).then((response) => {
      if (response.data.active) {
        notify({ message: translate('User activated with success!'), type: 'success' })
      } else {
        notify({ message: translate('User deactivated with success!'), type: 'success' })
      }
    }).finally(() => {
      openDialogConfirmDisabled.set(false)
      loading.set(false)
    }).catch((error) => {
      console.log(error)
    })
  }

  const extraActions = React.useMemo(() => {
    const actions: ActionButtonsProps[] = [];

    if (verifyPermission(['user_password_edit'])) {
      // eslint-disable-next-line functional/immutable-data
      actions.push(
        {
          iconButton: (params) => Boolean(params['active'] === 'Sim') ? <ToggleOnIcon /> : < ToggleOffOutlinedIcon />,
          label: (params) => Boolean(params['active'] === 'Sim') ? translate('Deactivate user') : translate('Active user'),
          action: (params) => {
            handleDialogConfirmOpenDisabled(params.id)

            Boolean(params['active'] === 'Sim') ? isEnabled.set(true) : isEnabled.set(false)
            userName.set(params['login'])
          },
          showInMenu: function () {
            return false
          },
        },
        {
          iconButton: <SyncLockRounded />,
          label: translate('Change Password'),
          action: (rowLogin) =>{ 
            history.push(`/user/change-password/connected/${rowLogin}`)
          },
          dialog: () => { return; },
          dialogContent: 'alo',
          dialogInformationColumn: 'login',
          showInMenu: function (params) {
            return params['is_connected']
          }
        },
        {
          iconButton: <PersonOffIcon />,
          label: translate('Disconnect'),
          action: (params) => { 
            handleDialogConfirmOpenDisconnect(params.id) 
            userName.set(params['login'])
          },
          showInMenu: function (params) {
            return !params['is_connected']
          }
        },
      )
    }
    return actions;
  }, []);

  const userFilters = [
    {
      name: 'user',
      type: 'name',
      placeholder: 'User',
      variable: user
    },
    {
      type: 'agency_id',
      variable: agencyId
    },
    {
      type: 'group_id',
      variable: permissionGroupId
    },
    {
      type: 'active',
      variable: active
    },
  ]

  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model='user'
        title='Users'
        createTitleComplement='user'
        exceptColumns={[
          'cpf',
          'birth_date',
          'blood_type',
          'email',
          'emergency_contact_name',
          'emergency_contact_phone',
          'agency_id', 'active',
          'is_connected',
          'acronym',
          'need_to_change_password',
        ]}
        extraActions={extraActions}
        booleanField={['need_to_change_password', 'active']}
        aliasLabel={[
          { column: 'login', label: 'User Login' },
          {column: 'groups', label:'Permission Groups'},
        ]}
        forceShowColumns={['active']}
        forceOrderColumns={['login', 'representation_name', 'registration', 'agency:name', 'groups', 'active']}
        arrayField={[{field: 'groups' , value: 'name'}]}
        notSortable={['groups', 'agency:name']}
        deleteable={false}
        filters={userFilters}
        disableColumnsFilters
        clearFilters={() => {
          agencyId.set(null)
          active.set(null)
          user.set('')
          permissionGroupId.set(null)
          forceRefresh.set(!forceRefresh.get())
        }}
        forceRefresh={forceRefresh.get()}
        searchButton={() => forceRefresh.set(!forceRefresh.get())}
        lockFields={lockFields.get()}
        canView
        showInViewMore={['login', 'name', 'email', 'cpf', 'registration', 'birth_date', 'phone', 'blood_type', 'emergency_contact_name', 'emergency_contact_phone', 'agency:name', 'rank:name', 'nickname', 'groups', 'dispatch_groups', 'created_at']}
      />

      <ConfirmDialog
        handleAgree={() => disconnect(userActionId.get())}
        handleClose={() => handleDialogConfirmDisconnect()}
        open={openDialogConfirmDisconnect}
        title={translate('Confirm user disconnect')}
        content={translate('Confirm user disconnect? When performing this action, the user will be logged out of the system.', { userName: userName.get() })}
        loading={loading}
      />

      <ConfirmDialog
        handleAgree={() => disabledUser(userActionId.get())}
        handleClose={() => handleDialogConfirmDisabled()}
        open={openDialogConfirmDisabled}
        title={isEnabled.get() ? translate('Confirm deactivation of the user') : translate('Confirm user activation')}
        content={isEnabled.get() ? translate('Confirm desactivate the user? By performing this action, the user will no longer be able to access the system.', { userName: userName.get() }) : translate('Confirm user activation? By performing this action, the user will be able to access the system.', { userName: userName.get() })}
        loading={loading}
      />

    </Layout>
  )
}