/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  createState,
  none,
  useState,
} from '@hookstate/core'
import { authFetch } from '~/services/fetch'
import { IDispacthRegistration } from '../interfaces/IDispacthRegistration'
import useDispatchRegistrationVehicleState from './DispatchRegistrationVehicleState';
import useDispatchRegistrationWeaponState from './DispatchRegistrationWeaponState';
import useDispatchRegistrationPersonState from './DispatchRegistrationPersonState';
import useDispatchRegistrationDrugState from './DispatchRegistrationDrugState';
import useDispatchRegistrationObjectState from './DispatchRegistrationObjectState';
import useDispatchRegistrationOrganState from './DispatchRegistrationOrganState';
import axios from 'axios';
import ICity from '~/features/City/interfaces/ICity';


const defaultRegistrationProps: IDispacthRegistration = {
  addRegistration: false,
  deleteRegistration: false,
  loadingRegistrations: false,
  openConfirmAction: false,
  externalAction: false,
  externalActionName: '',
  registrationActiveTab: 0,
  actionName: '',
  registrationRowId: '',
  inputErrors: {
    notes: false,
    cpf: false,
    destination: false,
    involvementType: false,
    phoneNumber: false,
    peopleCircunstance: false,
    gender: false,
    organization: false,
    document: false,
    arrested: false,
    birthDate: false,
    publicAgent: false,
    specieId: false,
    animalCircunstance: false,
    occupation: false,
  }
}

const registrationDispatchState = createState<IDispacthRegistration>(defaultRegistrationProps);

const useDispatchRegistrationState = () => {
  const registrationDispatch = useState(registrationDispatchState);
  const { registrationVehicleDispatch } = useDispatchRegistrationVehicleState();
  const { registrationWeaponDispatch } = useDispatchRegistrationWeaponState();
  const { registrationPersonDispatch } = useDispatchRegistrationPersonState();
  const { registrationDrugDispatch } = useDispatchRegistrationDrugState();
  const { registrationObjectDispatch } = useDispatchRegistrationObjectState();
  const { registrationOrganDispatch } = useDispatchRegistrationOrganState();
  const { vehicles } = registrationVehicleDispatch();
  const { weapons } = registrationWeaponDispatch();
  const { people } = registrationPersonDispatch();
  const { drugs } = registrationDrugDispatch();
  const { objects } = registrationObjectDispatch();
  const { organs } = registrationOrganDispatch();
  // const registrationStates = useState(registrationDispatch.registrationStates)

  return {
    registrationDispatch: () => registrationDispatch,
    registrationDispatchFlush: () => {
      vehicles.set(none)
      weapons.set(none)
      people.set(none)
      drugs.set(none)
      objects.set(none)
    },
    // getRowData: async (stateId: number, id: number, stateName: string) => {
    //   const targetUrl = `/associated-${stateName}/${id}`
    //   const { data: vehicle } = await authFetch({
    //     url: targetUrl,
    //     method: 'GET',
    //   });
    //   registrationStates[stateId]
    //     .set({
    //       id: vehicle.id,
    //       vehicleType: vehicle.vehicle_type,
    //       plate: vehicle.plate,
    //       owner: vehicle.owner,
    //       vehicleModel: vehicle.vehicle_model,
    //       vehicleColor: vehicle.vehicle_color,
    //       vehicleBrand: vehicle.vehicle_brand,
    //       notes: vehicle.notes,
    //       circunstance: vehicle.circunstance,
    //       destination: vehicle.destination,
    //       occurrenceId: vehicle.occurrence_id,
    //       ownerCpf: vehicle.owner_cpf,
    //       state: vehicle.state,
    //     });
    // },
    getCircunstances: async () => {
      const targetUrl = '/circunstance'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getDestinations: async () => {
      const targetUrl = '/destination'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getUnitMeasurement: async () => {
      const targetUrl = '/unit-measurement'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getStates: async () => {
      const targetUrl = '/state'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getCities: async (id?: string) => {
      if (!id) {
        return []
      }
      const targetUrl = `/state/${id}/cities`
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getCity: async (id: number | string) => {
      if (!id) return null;
      const targetUrl = `/city/${id}`
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getAddress: async (cep: string) => {
      const targetUrl = `https://viacep.com.br/ws/${cep}/json/`

      const response = await axios(targetUrl, {
        method: 'GET',
        signal: (new AbortController()).signal
      });

      return response
    }
  }
}

export default useDispatchRegistrationState;