/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { useHookstate } from '@hookstate/core'
import { Grid, Card, CardHeader, IconButton, Collapse } from '@mui/material'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'
import notify from '~/utils/notify'
import ManagementFilters from '../components/ManagementFilters'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import IListDispatchSocket from '~/components/ListDispatchesSocket/interfaces/IListDispatchesProps'
import ListDispatchesManagement from '../components/ListDispatchesManagement'
import { listManagementDispatchs, searchManagementDispatchs } from '../services'
import { GridRowId } from '@mui/x-data-grid'
import InternalHeader from '~/components/Layout/components/InternalHeader'

export default function ManagementDownload() {
  const { translate } = useLang()
  const expandFilterSearch = useHookstate<boolean>(true)
  const expandDispatchs = useHookstate<boolean>(false)
  const lockFields = useHookstate<boolean>(false)
  const labelButton = useHookstate(translate('Search'))

  const withSolvedThirdParty = useHookstate<boolean>(false)

  const categoryId = useHookstate<number | null>(null)
  const agencyIds = useHookstate<readonly number[]>([])
  const dispatchIds = useHookstate<readonly GridRowId[]>([])
  const deviceId = useHookstate<number | null>(null)
  const closedStatusIds = useHookstate<readonly number[]>([])
  const groupId = useHookstate<readonly number[]>([])
  const reportType = useHookstate('synthetic')

  const dispatches = useHookstate<readonly IListDispatchSocket[]>([])

  const typeId = useHookstate<number | null>(null)
  const subtypeId = useHookstate<number | null>(null)
  const initialPeriod = useHookstate<string>('1')
  const [startDate, setStartDate] = useState<string | null>(null)
  const [finishDate, setFinishDate] = useState<string | null>(null)
  const cityId = useHookstate<number | null>(null)
  const entryOriginId = useHookstate<number | null>(null)
  const entryOriginStation = useHookstate<number | null>(null)
  const operationId = useHookstate<number | null>(null)
  const groupSetId = useHookstate<number | null>(null)
  const occurrenceNumber = useHookstate<string | null>(null)
  const code = useHookstate<string | null>(null)
  const dispatchId = useHookstate<number | null>(null)
  const location = useHookstate<string | null>(null);
  const operationUnitId = useHookstate<number | null>(null);
  const requester = useHookstate<string | null>(null);
  const phone = useHookstate<string | null>(null);
  const statusId = useHookstate<number | null>(null)
  const closedStatusClassId = useHookstate<number | null>(null);
  const qualifiersSelected = useHookstate<number[]>([]);
  const clearState = useHookstate<boolean>(false);
  const consummatedAndAttempted = useHookstate<string | null>(null)
  const onlyToClerk = useHookstate(false)

  useEffect(() => {
    document.title = translate('Dispatch Statistics - Dispatch System')
  }, [])

  const isAwaiting = useHookstate(false)
  const isAwaitingReportType = useHookstate(false)

  const download = (filename: string, content: string) => {
    const element = document.createElement('a')

    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(content)
    )
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  const searchData = async () => {

    isAwaiting.set(true)
    const dispatchsData = await listManagementDispatchs({
      // startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
      // finishDate: finishDate.format('YYYY-MM-DD HH:mm:ss'),
      finishDate: finishDate,
      startDate: startDate,
      agencyIds: agencyIds.get(),
      closedStatusIds: closedStatusIds.get(),
      groupId: groupId.get(),
      typeId: typeId.get(),
      subtypeId: subtypeId.get(),
      categoryId: categoryId.get(),
      withSolvedThirdParty: withSolvedThirdParty.get(),
      cityId: cityId.get(),
      entryOriginId: entryOriginId.get(),
      operationId: operationId.get(),
      groupSetId: groupSetId.get(),
      occurrenceNumber: occurrenceNumber.get(),
      dispatchId: dispatchId.get(),
      location: location.get(),
      operationUnitId: operationUnitId.get(),
      deviceId: deviceId.get(),
      requester: requester.get(),
      phone: phone.get(),
      statusId: statusId.get(),
      closedStatusClassId: closedStatusClassId.get(),
      qualifiersSelected: qualifiersSelected.get(),
      consummated: consummatedAndAttempted.get(),
      onlyToClerk: onlyToClerk.get(),

    }).finally(() => isAwaiting.set(false))
    dispatches.set(dispatchsData)
    expandDispatchs.set(true)
  }

  const exportReport = async () => {
    isAwaitingReportType.set(true)
    const dispatchesData = await searchManagementDispatchs({
      dispatchIds: dispatchIds.get(),
      reportType: reportType.get(),
    })
      .catch(() => {
        isAwaitingReportType.set(false)
      })
      .finally(() => {
        isAwaitingReportType.set(false)
      })

    if (!dispatchesData) {
      notify({
        message: translate('No Content to Download'),
        type: 'error',
        autoCloseDuration: 3000,
      })
      return
    }
    download(
      `statistics-${reportType.get()}-${new Date().getTime()}.csv`,
      dispatchesData
    )
  }

  const disabledSendButton = () => {

    if (occurrenceNumber.get() || dispatchId.get()) {
      return false
    } else if (!startDate || !finishDate) {
      return true
    } else {
      return false
    }

  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <InternalHeader title={translate(`Statistics`)} />
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
            titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}
          />
          <ManagementFilters
            disabledReportType={!dispatches.get().length}
            loading={isAwaiting}
            labelButton={labelButton}
            reportType={reportType}
            agencyIds={agencyIds}
            categoryId={categoryId}
            clearFilters={() => {
              withSolvedThirdParty.set(false)
              deviceId.set(null)
              groupId.set([])
              agencyIds.set([])
              groupId.set([])
              categoryId.set(null)
              typeId.set(null)
              subtypeId.set(null)
              initialPeriod.set('')
              setFinishDate('')
              setStartDate('')
              reportType.set('synthetic')
              closedStatusIds.set([])
              labelButton.set(translate('Search'))
              expandDispatchs.set(false)
              dispatches.set([])
              cityId.set(null)
              entryOriginId.set(null)
              entryOriginStation.set(null)
              operationId.set(null)
              groupSetId.set(null)
              occurrenceNumber.set(null)
              dispatchId.set(null)
              code.set('')
              location.set('')
              operationUnitId.set(null)
              requester.set(null)
              phone.set(null)
              statusId.set(null)
              closedStatusClassId.set(null)
              qualifiersSelected.set([])
              clearState.set(!clearState.get())
              consummatedAndAttempted.set(null)
              onlyToClerk.set(false)

            }}
            searchButton={() => {
              // toExport.set()
              searchData()
            }}
            closedStatusIds={closedStatusIds}
            deviceId={deviceId}
            groupId={groupId}
            initialPeriod={initialPeriod}
            disabledSendButton={disabledSendButton}
            lockFields={lockFields.get()}
            onStartDateChange={(value) => {
              labelButton.set(translate('Search'))
              setStartDate(value)
            }}
            onFinishDateChange={(value) => {
              labelButton.set(translate('Search'))
              setFinishDate(value)
            }}
            onPeriodChange={(value) => {
              labelButton.set(translate('Search'))
              initialPeriod.set(value)
            }}
            typeId={typeId}
            withSolvedThirdParty={withSolvedThirdParty}
            hidden={expandFilterSearch.value}
            subtypeId={subtypeId}
            cityId={cityId}
            entryOriginId={entryOriginId}
            entryOriginStation={entryOriginStation}
            operationId={operationId}
            groupSetId={groupSetId}
            occurrenceNumber={occurrenceNumber}
            dispatchId={dispatchId}
            code={code}
            location={location}
            operationUnitId={operationUnitId}
            requester={requester}
            phone={phone}
            statusId={statusId}
            closedStatusClassId={closedStatusClassId}
            qualifiersSelected={qualifiersSelected}
            clearState={clearState}
            consummatedAndAttempted={consummatedAndAttempted}
            onlyToClerk={onlyToClerk}
          />
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={translate('Dispatch')}
            avatar={
              <IconButton
                onClick={() => expandDispatchs.set(!expandDispatchs.get())}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandDispatchs.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
            titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}
          />
          <Collapse in={expandDispatchs.value}>
            <ListDispatchesManagement
              dispatchIds={dispatchIds}
              dispatches={dispatches}
              reportType={reportType}
              lockFields={lockFields.get()}
              searchButton={() => exportReport()}
              loading={isAwaitingReportType}
            />
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
