import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import CrudEdit from '~/components/CrudEdit';
import ICrudEditParams from '~/components/CrudEdit/interfaces/ICrudEditParams';
import IUser from '~/features/User/interfaces/IUser';
import notify from '~/utils/notify';

export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue, getValues} = useForm<IUser>();
  const fields = [
    { name: 'id', type: 'hidden'},
    { name: 'login', type: 'string', placeholder: 'User Login', required: true },
    { name: 'name', type: 'string', required: true },
    { name: 'email', type: 'string'},
    { name: 'cpf', type: 'string', mask: '999-999-999-99'},
    { name: 'registration', type: 'string'},
    { name: 'birth_date', type: 'date'},
    { name: 'phone', type: 'string', mask: '(99) 99999-9999' },
    { name: 'blood_type', type: 'select', options: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'], placeholder: 'Blood Type'},
    { name: 'emergency_contact_name', type: 'string'},
    { name: 'emergency_contact_phone', type: 'string', mask: '(99) 99999-9999' },
    { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder: 'Agency:name', required: true },
    { name: 'rank_id', type: 'selectAutoComplete', model: 'rank', placeholder: 'Patent' },
    { name: 'nickname', type: 'string', placeholder: 'war_name' },
    { name: 'group', type: 'transferList', options: {firstListUrl: `/user/${useParams<ICrudEditParams>().id}/get-groups`, secondListUrl: '/group'}, placeholder: 'Permission Group'},
    { name: 'dispatch_groups', type: 'transferList', options: { firstListUrl: `/user/${useParams<ICrudEditParams>().id}/dispatch-groups`, secondListUrl: '/dispatch/group' }, placeholder: 'Dispatch Group' }
  ];

    return(
      <CrudEdit 
        model={'user'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'User successfully updated!'}  
        title={'Edit user'}
        getValues={getValues}
      />
    )
}
