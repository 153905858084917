import { State, useHookstate } from '@hookstate/core'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterItem,
  GridPreferencePanelsValue,
  GridRowModel,
  GridRowParams,
  GridSortModel,
  getDataGridUtilityClass,
  gridClasses,
  gridPreferencePanelStateSelector,
  useGridApiContext,
  useGridRootProps
} from '@mui/x-data-grid'
import { Tooltip } from '@mui/material';
import { ptBR } from '@mui/x-data-grid/locales';
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import FilterIcon from '@mui/icons-material/FilterAlt'

import { capitalize, hifenToUnderline } from '../../utils/strings'
import ConfirmDialog from '../ConfirmDialog'

import {
  Badge,
  Box,
  IconButton,
  unstable_composeClasses as composeClasses,
} from '@mui/material'
import useUserState from '~/features/User/stores/UserState'
import { useLang } from '~/hooks/useLang'
import routers from '~/routes/routers'
import { authFetch } from '~/services/fetch'
import { dateTimeFormat } from '~/utils/dateTime'
import notify from '~/utils/notify'
import { ActionButtonsProps } from '../CrudIndex/interfaces/ICrudIndex'
import { SimpleDialogBox } from '../SimpleDialogBox'
import { FilterArrayProps } from '../CrudIndex/components/FiltersDashboard'
import { Visibility } from '@mui/icons-material';
import CrudViewMore from '../CrudViewMore';
import { info } from 'console';

export type AliasLabelProps = {
  readonly column: string
  readonly label: string
}

export type ArrayFieldProps = {
  field: string;
  value: string;
}

export type CellLabelProps = {
  field: string;
  value: any;
}

type TableProps = {
  readonly model: string
  readonly exceptColumns?: readonly string[]
  readonly forceShowColumns?: readonly string[]
  readonly aliasLabel?: readonly AliasLabelProps[]
  readonly extraActions?: readonly ActionButtonsProps[]
  readonly forceRefresh?: boolean
  readonly booleanField?: readonly string[]
  readonly stringField?: readonly string[]
  readonly datesField?: readonly string[]
  readonly desableDelete?: boolean
  readonly forceOrderColumns?: string[];
  readonly arrayField?: ArrayFieldProps[];
  readonly notSortable?: string[];
  readonly deleteable?: boolean;
  readonly forceBooleanField?: readonly string[]
  readonly filters?: FilterArrayProps[],
  readonly disableColumnsFilters?: boolean
  readonly editable?: boolean;
  readonly cellLabel?: CellLabelProps[];
  readonly canView?: boolean;
  readonly showInViewMore: string[];
  readonly viewTitle?: string;
  readonly priorityActions?: readonly ActionButtonsProps[];
  readonly permissionName?: string;
}

interface ObjectFiltersProps {
  [type: string]: any;
}

// eslint-disable-next-line max-lines-per-function
export default function DataTables(props: TableProps) {
  const tableMode = 'server'

  const exceptColumns = [
    ...['authentication_key', 'id', 'created_at', 'updated_at', 'deleted_at'],
    ...(props.exceptColumns ? props.exceptColumns : []),
  ].filter((column) =>
    props.forceShowColumns ? !props.forceShowColumns.includes(column) : column
  )

  const history = useHistory()
  const { translate } = useLang()
  const { verifyPermission } = useUserState()

  const [columns, setColumns] = useState<GridColDef[]>([])
  const [rows, setRows] = useState<readonly GridRowModel[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState<any>(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState<readonly GridFilterItem[]>([])
  const [sort, setSort] = useState<GridSortModel>([])

  const openDialog = useHookstate(false)
  const openDialogBox = useHookstate(false)
  const dialogBoxTitle = useHookstate('')
  const dialogBoxContent = useHookstate('')
  const [rowId, setRowId] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [booleanField, setBooleanField] = useState<any>(props.booleanField)
  const [stringField, setStringField] = useState<readonly string[]>(
    props.stringField ?? []
  )
  const [datesField, setDatesField] = useState<any>(props.datesField)
  const location = useLocation()
  const protecteds = routers
  const currentLocation = location.pathname
  const canPermission = protecteds.find(
    (element) => element.path === currentLocation,
  )
  const existPermission = canPermission?.permissions ?? []
  const canPermissionEdit =
    existPermission.length > 0
      ? props.model || props.permissionName
        ? verifyPermission([hifenToUnderline(props.permissionName ? props.permissionName : props?.model) + '_edit'])
        : true
      : true
  const canPermissionDelete =
    props?.desableDelete === false
      ? existPermission.length > 0
        ? props.model || props.permissionName
          ? verifyPermission([props.model == 'procedure/config' ? 'procedure_configs_delete' : hifenToUnderline(props.permissionName ? props.permissionName : props?.model) + '_delete'])
          : true
        : true
      : false
  const openViewInfo = useHookstate(false)
  const infoId = useHookstate<number | null>(null)
  const infoViewMore = useHookstate<any[]>([])

  const keysExcept = (obj: object) =>
    Object.keys(obj).filter((key) => !exceptColumns.includes(key))

  useEffect(() => {
    const targetUrl = `/${props.model}`
    setLoading(true)

    const objFilters = props.filters?.reduce((obj, item) => {
      obj[item.name ? item.name : item.type] = item.variable.get();
      return obj;
    }, {})

    authFetch({
      url: targetUrl,
      data: {
        filters: filters,
        orders: sort,
        page: page,
        limit: limit,
        ...objFilters
      },
    })
      .then(({ data: { data, rowsCount } }) => {
        if (rowsCount > 0) {
          if (columns.length === 0) {
            const requestColumns: GridColDef[] = props.forceOrderColumns ? props.forceOrderColumns.map(
              value => {
                const headerName = props.aliasLabel?.find(alias => alias.column == value)
                const isSortable = props.notSortable?.find(item => item == value) ? false : true
                return {
                  field: value,
                  headerName: translate(capitalize(headerName ? headerName.label : value)),
                  editable: false,
                  disableExport: true,
                  flex: 1,
                  sortable: isSortable
                }
              }
            ) : getElementColumns(data[0])

            setColumns([...requestColumns, getElementActions()])

          }
        }
        setRows(setRowsValues(data, booleanField, datesField, stringField))
        setRowCount(rowsCount)

      })
      .finally(() => setLoading(false))
  }, [page, filters, columns, sort, limit, props?.forceRefresh])

  const handleDialogOpen = () => {
    openDialog.set(true)
  }

  const handleDialogClose = () => {
    openDialog.set(false)
  }

  const handleDialogCloseFunction = () => {
    const url = `/${props.model}/${rowId}`
    authFetch({
      url: url,
      method: 'DELETE',
    }).then((response) => {
      setRows(rows.filter((el) => el.id != rowId))
      handleDialogClose()
      setFilters([])
      if (response.status === 200) {
        notify({ message: translate('Successfully Deleted'), type: 'success' })
      }
    })
  }

  function handleDelete(id: number) {
    handleDialogOpen()
    setRowId(id)
  }

  function handleEdit(id: number) {
    history.push(`/${props.model}/edit/${id}`)
  }

  const handleDialogBoxOpen = () => {
    openDialogBox.set(true)
  }

  const handleDialogBoxClose = () => {
    openDialogBox.set(false)
  }

  function setRowsValues(
    dataset: readonly object[] | readonly [],
    booleanFields = [],
    dateFields = [],
    stringFields: readonly string[] = []
  ) {
    return dataset.map((dta: any) => {
      const currentRow = Object.keys(dta).reduce((acc: any, curr: any) => {
        if (dta[curr] != null && booleanFields != undefined) {
          booleanFields.map((value) => {
            if (curr === value) {
              dta[curr] = dta[curr] == 1 ? 'Sim' : 'Não'
            }
          })
        }

        if (props.forceBooleanField != undefined) {
          props.forceBooleanField.map((value) => {
            if (curr === value) {
              dta[curr] = dta[curr] != null ? 'Sim' : 'Não'
            }
          })
        }

        if (dta[curr] != null && props.arrayField != undefined) {
          props.arrayField.map(arrayData => {
            if (curr === arrayData.field) {

              const arrayStr = dta[curr].map(data => data[arrayData.value])
              const valueUnion = arrayStr.join(', ')
              dta[curr] = valueUnion

            }

          })
        }

        if (dta[curr] != null && props.cellLabel != undefined) {
          props.cellLabel.map(cellData => {
            if (curr === cellData.field) {
              const label = cellData.value[dta[curr]]
              dta[curr] = label
            }
          })
        }

        if (dta[curr] != null && dateFields != undefined) {
          dateFields.map((value) => {
            if (curr === value) {
              dta[curr] = dta[curr] ? dateTimeFormat(dta[curr]) : '-'
            }
          })
        }

        if (stringFields != undefined) {
          stringFields.map((value) => {
            if (curr === value) {
              dta[curr] = dta[curr] ? dta[curr] : null
            }
          })
        }

        if (dta[curr] != null && typeof dta[curr] === 'object') {
          const dataLine = dta[curr]


          const objectValue = keysExcept(dataLine)
            .map((key) => {
              const newObjectKey = `${curr}:${key}`

              if (dataLine[key] != null && typeof dataLine[key] === 'object') {
                if ('name' in dataLine[key]) {
                  dataLine[key] = dataLine[key].name
                }
              }

              return {
                [newObjectKey]: dataLine[key],
              }
            })
            .reduce(function (acc, curr) {
              acc = Object.assign(acc, curr)
              return acc
            }, {})

          acc = Object.assign(acc, objectValue)
        } else {
          acc[curr] = dta[curr]
        }



        return acc
      }, {})

      return currentRow
    })
  }

  function getElementActions(): any {
    return {
      field: 'actions', type: 'actions',
      flex: 1,
      getActions: (params: GridRowParams) => [
        ...getExtraElementActions(params.row, props.priorityActions),
        props.canView ? (
          <GridActionsCellItem
            key={2}
            icon={<Visibility />}
            onClick={() => {
              openViewInfo.set(true)
              infoId.set(params.row.id)
              infoViewMore.set(params.row)
            }}
            label="Visualizar"
            title="Visualizar"
          />
        ) : (
          <></>
        )
        ,
        props.editable ? (
          <GridActionsCellItem
            key={1}
            icon={<EditIcon />}
            disabled={!canPermissionEdit}
            onClick={() => handleEdit(params.row.id)}
            label="Editar"
            title="Editar"
          />
        ) : (
          <></>
        ),
        props.deleteable ? (
          <GridActionsCellItem
            key={2}
            icon={<DeleteIcon />}
            disabled={!canPermissionDelete}
            onClick={() => handleDelete(params.row.id)}
            label="Excluir"
            title="Excluir"
          />
        ) : (
          <></>
        ),
        
        ...getExtraElementActions(params.row, props.extraActions),
      ],
    }
  }
  function getExtraElementActions(row, actions) {
    if (!actions) {
      return []
    }

    return [
      ...actions.map((item, index) => {
        const {
          iconButton,
          action,
          dialogInformationColumn,
          label,
          showInMenu,
        } = item
        const actionParam = dialogInformationColumn
          ? row[dialogInformationColumn]
          : row

        const icon =
          typeof iconButton == 'function' ? iconButton(actionParam) : iconButton
        const labelButton =
          typeof label == 'function' ? label(actionParam) : label

        return (
          <GridActionsCellItem
            key={index + 3}
            icon={icon ?? <EditIcon />}
            onClick={() => action(actionParam)}
            label={labelButton ?? label}
            title={labelButton ?? label}
            disabled={showInMenu ? showInMenu(row) : false}
          />
        )
      }),
    ]
  }

  function getElementColumns(object: { readonly [key: string]: any }) {
    const keys = keysExcept(object).filter(
      (key) => typeof object[key] != 'object'
    )

    const parentKeys = keysExcept(object).filter(
      (key) => object[key] != null && typeof object[key] === 'object'
    )

    for (const parentKey of parentKeys) {
      if (!props.arrayField?.find(value => value.field == parentKey)) {
        const kss = keysExcept(object[parentKey]).map(
          (children) => `${parentKey}:${children}`
        )
        keys.push(...kss)
      } else {
        keys.push(parentKey)


      }



    }

    stringField.map((str) => {
      const verifyStringField = object.hasOwnProperty(str)

      if (verifyStringField && !keys.includes(str)) {
        keys.push(str)
      }
    })

    const aliasLabel = props.aliasLabel

    if (keys.includes('active') && currentLocation === '/user') {
      keys.push(keys.splice(keys.indexOf('active'), 1)[0])
    }

    if (props.forceShowColumns) {
      keys.concat(...(props.forceShowColumns as readonly string[]))
    }

    return keys.map((key) => {
      const headerName = aliasLabel
        ? aliasLabel.reduce((header: string, headers: AliasLabelProps) => {
          if (headers.column == key) {
            header = headers.label
          }

          return header
        }, key)
        : key

      const isSortable = key == 'agency:name' || key == 'groups' ? false : true

      return {
        field: key,
        headerName: translate(capitalize(headerName)),
        headerClassName: 'theme-header',
        editable: false,
        disableExport: true,
        flex: 1,
        sortable: isSortable
      }
    })
  }

  const useUtilityClasses = (ownerState) => {
    const { classes } = ownerState

    const slots = {
      icon: ['filterIcon'],
    }

    return composeClasses(slots, getDataGridUtilityClass, classes)
  }

  function CustomFilterIconButton(props) {
    const { counter, field, onClick } = props
    const apiRef = useGridApiContext()
    const rootProps = useGridRootProps()
    const ownerState = { ...props, classes: rootProps.classes }
    const classes = useUtilityClasses(ownerState)

    const column = apiRef.current.getColumn(field)
    if (!column.filterable) {
      return null
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toggleFilter = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const { open, openedPanelValue } = gridPreferencePanelStateSelector(
          apiRef.current.state
        )

        if (open && openedPanelValue === GridPreferencePanelsValue.filters) {
          apiRef.current.hideFilterPanel()
        } else {
          apiRef.current.showFilterPanel(field)
        }

        if (onClick) {
          onClick(apiRef.current.getColumnHeaderParams(field), event)
        }
      },

      [apiRef, field, onClick]
    )

    const iconButton = (
      <IconButton
        onClick={toggleFilter}
        color="default"
        aria-label={apiRef.current.getLocaleText('columnHeaderFiltersLabel')}
        size="small"
        tabIndex={-1}
        title={translate('Filter')}
      >
        <FilterIcon fontSize="small" />
      </IconButton>
    )

    return (
      <Tooltip title='' enterDelay={1000}>
        <Box sx={{ display: 'flex' }}>
          {counter > 1 && (
            <Badge badgeContent={counter} color="default">
              {iconButton}
            </Badge>
          )}
          {(!counter || counter === 1) && iconButton}
        </Box>
      </Tooltip>
    )
  }

  return (
    <>
      <DataGrid
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        pageSizeOptions={[limit]}
        columns={columns}
        // autoHeight
        initialState={{
          pagination: { paginationModel: { pageSize: limit } },
        }}
        pagination
        rowCount={rowCount}
        paginationMode={tableMode}
        filterMode={tableMode}
        sortingMode={tableMode}
        loading={loading}
        onPaginationModelChange={(paginationModel) => setPage(paginationModel.page)}
        onFilterModelChange={(onFilterChange) =>
          setFilters(onFilterChange['items'])
        }
        onSortModelChange={(onSortChange) => setSort(onSortChange)}
        disableColumnSelector={true}
        slots={{ columnHeaderFilterIconButton: CustomFilterIconButton }}
        sx={{
          [`.${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 'bold !important',
          },
          [`& .${gridClasses.columnHeader}:not(.${gridClasses['columnHeader--filtered']}) .${gridClasses.filterIcon}`]:
            (theme) => ({
              opacity: filters.map((element) =>
                element.value?.length > 0 ? 0.2 : 1
              ),
              transition: theme.transitions.create(['opacity'], {
                duration: theme.transitions.duration.shorter,
              }),
            }),
          ['.MuiDataGrid-overlay']: {
            alignItems: 'center',
          },
          height: rows.length > 0 ? 'auto' : 200
        }}
        disableColumnFilter={props.disableColumnsFilters ? props.disableColumnsFilters : false}
      />
      <ConfirmDialog
        handleAgree={handleDialogCloseFunction}
        handleClose={handleDialogClose}
        open={openDialog}
        title={translate('Confirmation')}
        content={translate('Confirm delete?')}
      />
      <SimpleDialogBox
        handleClose={handleDialogBoxClose}
        open={openDialogBox}
        title={dialogBoxTitle}
        content={dialogBoxContent}
      />
      <CrudViewMore 
      model={props.model}
      viewTitle={props.viewTitle ? props.viewTitle : props.model}
      open={openViewInfo} 
      infoId={infoId.get()}
      handleClose={() => {
        openViewInfo.set(false)
        infoId.set(null)
        infoViewMore.set([])
      }} 
      showInViewMore={props.showInViewMore}
      booleanField={props.booleanField}
      />
    </>
  )
}
