import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { InsertDriveFile } from '@mui/icons-material';
import DispatchForm from '../DispatchForm';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useDispatchState from '../../stores/DispatchState';
import { Validation as validation } from '@hookstate/validation';
import { Grid, Typography, Chip, Stack, Tooltip } from '@mui/material';
import useSystemState from '~/features/System/stores/SystemState';
import { useHookstate } from '@hookstate/core';
import { convertMsToTimeFriendly, dateTimeFormat } from '~/utils/dateTime';
import DispatchModalLocation from './Components/DispatchModalLocation';
import DispatchModalInfo from './Components/DispatchModalInfo';
import DispatchTravelInfo from './Components/DispatchTravelInfo';
import { storeScreenSettings } from '~/features/System/services';
import { useLocation } from 'react-router';
import { getDispatchProcedures } from '../../services';


const modal = document.getElementById('div-modal') as HTMLElement;

const elapsedTime = (initialDateTime?: string): string => {
  if (initialDateTime) {
    const dataInicial = new Date(initialDateTime)
    const dataAtual = new Date();
    const tempoDecorrido = dataAtual.getTime() - dataInicial.getTime();
    return convertMsToTimeFriendly(tempoDecorrido);
  }
  else
    return 'N/D';
}

export default function DispatchFormModalDraggable() {
  const { translate } = useLang();
  const { dispatch } = useDispatchState();
  const location = useLocation()
  const { id, code, type, subtype, status, occurrence, dispatchGroup, dispatchedAt } = dispatch();
  const title = `${validation(id).valid() ? `${translate('OC')} ${occurrence?.id.get()} - ${code.get()}` : ''}`
  const { getStatusColors, windowManager, loadingButton } = useSystemState();

  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    windowManager()['dispatch'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'DispatchFormModalDraggable',
        isOpen: windowManager()['dispatch'].open.get(),
        isMinimized: windowManager()['dispatch'].minimized.get(),
        positionX: windowManager()['dispatch'].positionX.get(),
        positionY: windowManager()['dispatch'].positionY.get()
      })

    }
  }, [
    windowManager()['dispatch'].open.get(),
    windowManager()['dispatch'].minimized.get(),
    windowManager()['dispatch'].positionY.get(),
    windowManager()['dispatch'].positionX.get()
  ])

  if (location.pathname === '/dispatch' || location.pathname === '/dispatch/management-map') {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() &&
          <StandardModal
            title={
              status.get() && status.id.get() ? (
                <Grid container sx={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Grid item sx={{ alignSelf: 'flex-start', marginRight: 'auto' }}>
                    <Typography>
                      {title}
                    </Typography>
                    <Typography fontSize={14}>
                      {dispatchGroup.get()?.name ?? ''}
                      {' '}
                      {occurrence?.operation?.id?.get() ? `| ${translate('Operation')}: ${occurrence?.operation?.name?.get()}` : ''}
                    </Typography>
                  </Grid>

                  <Grid item sx={{ alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', padding: '5px', gap: 1 }}>

                    {occurrence?.calls?.get() > 1 &&
                      <Stack direction='row' alignItems='center' gap={1} >
                        <Tooltip title={translate('Number of recalls')} placement='top'>
                          <Chip variant={'outlined'} label={`${translate('Recalls')}: ${occurrence?.calls?.get() as number - 1} `} />
                        </Tooltip>
                      </Stack>
                    }

                    <Stack direction='row' alignItems='center' gap={1} >
                      <Tooltip title={`Despachado em: ${dispatchedAt.get() ? dateTimeFormat(dispatchedAt.get()?.toString()) : ''}`} placement='top'>
                        <Chip variant={'outlined'} label={elapsedTime((dispatchedAt.get() ? dispatchedAt.get()?.toString() : ''))} />
                      </Tooltip>
                    </Stack>

                    <Chip
                      sx={{
                        textAlign: 'center',
                        backgroundColor: getStatusColors(status.id.get()).main,
                        color: getStatusColors(status.id.get()).contrastText,
                      }} label={status?.name.get()} />
                  </Grid>

                  {/* <Grid item sx={{ alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', padding: '5px' }}>
                  </Grid> */}

                  <Grid item sx={{ alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', padding: '5px' }}>
                    <DispatchTravelInfo
                      title='Locate on the map'
                      latitude={occurrence?.latitude?.get()}
                      longitude={occurrence?.longitude.get()}
                    />
                  </Grid>

                  {Boolean(windowManager()['dispatch'].minimized.get()) &&
                    <Grid item sx={{ alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', padding: '5px' }}>
                      <DispatchModalLocation
                        latitude={occurrence.get().latitude}
                        longitude={occurrence.get().longitude}
                        modalIsExpanded={windowManager()['dispatch'].minimized.get()}
                      />
                    </Grid>
                  }

                  <Grid item sx={{ alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', padding: '5px' }}>
                    <DispatchModalInfo
                      title='Resume'
                      code={code.get()}
                      latitude={occurrence?.latitude.get()}
                      longitude={occurrence?.longitude.get()}
                      location={occurrence?.location.get()}
                      statusName={status?.name.get()}
                      typeName={type?.name.get()}
                      subtypeName={subtype?.name.get()}
                    />
                  </Grid>

                </Grid>
              ) : <Typography>
                {title}
              </Typography>
            }
            handleStop={handleStop}
            avatar={InsertDriveFile}
            lat={occurrence ? (occurrence.latitude ? occurrence.latitude.get() : null) : null}
            lng={occurrence ? (occurrence.longitude ? occurrence.longitude.get() : null) : null}
            dimensions={{ width: 1000 }}
            position={{
              x: windowManager()['dispatch'].positionX.get() ? Number(windowManager()['dispatch'].positionX.get()) : 600,
              y: windowManager()['dispatch'].positionY.get() ? Number(windowManager()['dispatch'].positionY.get()) : 100
            }}
            isExpanded={windowManager()['dispatch'].minimized}
          >
            <DispatchForm readonly={windowManager()['dispatch'].readonly.value} />

          </StandardModal>
        }
      </>

      , modal)
  } else {
    return <></>
  }

}