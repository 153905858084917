import { GridRowId } from "@mui/x-data-grid";
import { authFetch } from "~/services/fetch";

type ISearch = {
  readonly deviceId: number | null | undefined
  readonly startDate: string | null | undefined
  readonly finishDate: string | null | undefined
}

export interface ISearchManagementDispatchs {
  readonly startDate?: string | null;
  readonly finishDate?: string | null;
  readonly agencyIds?: readonly number[];
  readonly dispatchIds?: readonly GridRowId[];
  readonly closedStatusIds?: readonly number[];
  readonly groupId?: readonly number[];
  readonly typeId?: number | null;
  readonly subtypeId?: number | null;
  readonly categoryId?: number | null;
  readonly withSolvedThirdParty?: boolean;
  readonly reportType?: string;
  readonly cityId?: number | null;
  readonly entryOriginId?: number | null;
  readonly operationId?: number | null;
  readonly groupSetId?: number | null;
  readonly occurrenceNumber?: string | null;
  readonly dispatchId?: number | null;
  readonly location?: string | null;
  readonly operationUnitId?: number | null;
  readonly deviceId?: number | null;
  readonly requester?: string | null;
  readonly phone?: string | null;
  readonly statusId?: number | null;
  readonly closedStatusClassId?: number | null;
  readonly qualifiersSelected?: number[];
  readonly consummated?: string | null;
  readonly onlyToClerk?: boolean;
}

const getReportType = (reportType) => {
  switch (reportType) {
    case 'synthetic':
      return '/dispatch/csv/synthetical'
    case 'complete':
      return '/dispatch/csv/complete'
    default:
      return `/occurrence/associated-${reportType}/generate-csv`
  }
}

export async function searchDevicesLogs({ deviceId, finishDate, startDate }: ISearch) {
  try {
    const targetUrl = '/device/logs/connection-log'
    const { data } = await authFetch({
      url: targetUrl,
      data: {
        device_id: deviceId,
        start_date: startDate,
        finish_date: finishDate
      }
    })
    return data;
  } catch (error) {
    throw error
  }
}

export async function searchManagementDispatchs({
  reportType,
  dispatchIds,
}: ISearchManagementDispatchs) {
  try {
    const { data } = await authFetch({
      url: getReportType(reportType),
      method: 'POST',
      data: {
        dispatch_ids: dispatchIds,
      }
    })
    return data;
  } catch (error) {
    throw error
  }
}

export async function listManagementDispatchs({
  finishDate,
  startDate,
  agencyIds,
  closedStatusIds,
  groupId,
  typeId,
  subtypeId,
  categoryId,
  withSolvedThirdParty,
  cityId,
  entryOriginId,
  operationId,
  groupSetId,
  occurrenceNumber,
  dispatchId,
  location,
  operationUnitId,
  deviceId,
  requester,
  phone,
  statusId,
  closedStatusClassId,
  qualifiersSelected,
  consummated,
  onlyToClerk
}: ISearchManagementDispatchs) {
  try {
    const { data } = await authFetch({
      url: '/dispatch/csv/details',
      method: 'POST',
      data: {
        start_date: startDate,
        finish_date: finishDate,
        exclude_closed: false,
        agency_ids: agencyIds,
        closed_status_ids: closedStatusIds,
        dispatch_group_ids: groupId,
        type_id: typeId,
        subtype_id: subtypeId,
        category_id: categoryId,
        with_solved_third_party: withSolvedThirdParty,
        city_id: cityId,
        entry_origin_id: entryOriginId,
        operation_id: operationId,
        group_set_id: groupSetId,
        occurrence_id: occurrenceNumber,
        dispatch_id: dispatchId,
        location: location,
        operation_unit_id: operationUnitId,
        device_id: deviceId,
        name: requester,
        phone: phone,
        status_id: statusId,
        closed_status_class_id: closedStatusClassId,
        qualifier_options: qualifiersSelected,
        consummated: consummated,
        only_to_clerk :onlyToClerk,

      }
    })
    return data;
  } catch (error) {
    throw error
  }
}