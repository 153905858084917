import { authFetch } from "~/services/fetch";
import { PermissionGroupResponse } from "../interface";

export async function getPermissionGroup(): Promise<readonly PermissionGroupResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/group',
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}