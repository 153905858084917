import React from 'react';
import Logo from '../Logo';
import { AppBar, Box, Toolbar, Link, Grid, Chip } from '@mui/material';
import './styles.scss';
import SettingsButton from '../SettingsButton';
import MessageButton from '~/features/Message/components/MessageButton';
import WindowManagerButton from '~/features/System/components/WindowManagerButton';
import ColorInfoButton from '~/components/ColorInfoButton';
import UserButton from '~/features/User/components/UserButton';
import NotificationsCentral from '../NotificationsCentral';
import ChangeViewDispatchGroup from '~/features/User/components/ChangeViewDispatchGroup';
import userUserState from '~/features/User/stores/UserState';
import { useLang } from '~/hooks/useLang';
import { useAuth } from '~/features/auth/hooks/useAuth';

interface TopbarProps {
  readonly draggableComponents?: boolean;
  readonly mapViewPort?: boolean;
}

export default function Topbar({ draggableComponents = false, mapViewPort = false }: TopbarProps) {
  const { verifyPermission } = userUserState();
  const { translate } = useLang();
  const { onlyReadMode } = useAuth();

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar position="static" color="transparent" enableColorOnDark={true}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0px',
          }}
        >
          <Grid container xs={12}>
            <Grid container xs={4} paddingTop={1} paddingBottom={1}>
              <Grid item xs={6} maxHeight={'55px'} >
                <Link href='/'>
                  <Logo />
                </Link>
              </Grid>
            </Grid>

            <Grid container xs={8} direction="row" alignItems="center" spacing={2}>
              <Grid
                container
                item
                xs={6}
                justifyContent="flex-start"
                alignItems="center"
              />
              <Grid container item xs={6} justifyContent="flex-end" alignItems={'center'}>

                {onlyReadMode && <Chip label={<b>{translate('Only read mode')}</b>} variant='filled' />}

                <div id={'topbar-map-favorite-location'}></div>
                {location.pathname != '/dispatch/management-map' &&
                  <NotificationsCentral />
                }
                {draggableComponents && <WindowManagerButton />}
                <ColorInfoButton />
                <MessageButton />
                <SettingsButton />
                {(verifyPermission(['dispatch_index'])) && (
                  <ChangeViewDispatchGroup />
                )}
                <UserButton />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box >
  )
}
