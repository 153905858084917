import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getPermissionGroup } from './service';
import { PermissionGroupResponse } from './interface';

interface FilterAutocompletePermissionGroupProps {
    readonly onGroupChange: (value: number | null) => void;
    readonly groupId?: number | null
    readonly disabled?: boolean
}

export default function FilterAutocompletePermissionGroup({ disabled, onGroupChange, groupId }: FilterAutocompletePermissionGroupProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly PermissionGroupResponse[]>([]);
    const [optionsFilter, setOptionsFilter] = useState<readonly PermissionGroupResponse[]>([]);
    const [value, setValue] = useState<PermissionGroupResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getPermissionGroup().then(species => {
            setOptions(species)
            setOptionsFilter(species)
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (!groupId) {
            setValue(null)
        }
    }, [groupId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-group'
                options={optionsFilter}
                disabled={disabled}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onGroupChange(type?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Permission Group')}
                    />
                )}
            />
        </FormControl>
    )
}