/* eslint-disable new-cap */
import { createState, useState } from "@hookstate/core";
import IDispatchOccurrence from "../interfaces/IDispatchOccurrence";
import { Broadcasted } from "@hookstate/broadcasted";
import useOccurrenceState from "./OccurrenceState";
import { getDispatchesForOccurrenceById } from "../services";

const occurrenceDispatchesRelatedState = createState<readonly IDispatchOccurrence[]>([]);
const syncOccurrenceDispatchesRelatedState = createState(false);
const updatedStatusOccurrenceDispatchesRelatedState = createState(false);

// occurrenceDispatchesRelatedState.attach(Broadcasted('dispatch-related-events'));
// syncOccurrenceDispatchesRelatedState.attach(Broadcasted('sync-dispatch-related'));
// updatedStatusOccurrenceDispatchesRelatedState.attach(Broadcasted('update-sate-related'));

export default function useOccurrenceDispatchesRelated() {
  const occurrenceDispatchesRelated = useState(occurrenceDispatchesRelatedState);
  const syncOccurrenceDispatchesRelated = useState(syncOccurrenceDispatchesRelatedState);
  const updatedStatusOccurrenceDispatchesRelated = useState(updatedStatusOccurrenceDispatchesRelatedState)
  const { occurrence } = useOccurrenceState();
  
  return ({
    occurrenceDispatchesRelated: () => occurrenceDispatchesRelated,
    syncOccurrenceDispatchesRelated: syncOccurrenceDispatchesRelated,
    updatedStatusOccurrenceDispatchesRelated: updatedStatusOccurrenceDispatchesRelated,
    updateStatusOccurrenceDispatchesRelated: ({ dispatchesOccurrence }) => {
      dispatchesOccurrence().get().map((occurrenceGlobal) =>
        occurrenceGlobal.dispatches.map((dispatchGlobal) =>
          occurrenceDispatchesRelated.filter((dispatchLocal) =>
            dispatchLocal.id.get() === dispatchGlobal.id ? dispatchLocal.status.set(JSON.parse(JSON.stringify(dispatchGlobal.status))) : '')))
      updatedStatusOccurrenceDispatchesRelated.set(true)
    },
    updateListOccurrenceDispatches: ({ dispatchesOccurrence }) => {
      occurrenceDispatchesRelated.set([])
      dispatchesOccurrence().get().filter((occurrenceItem) =>
        occurrenceItem.id === occurrence().id.get()).map((occurrenceFiltered) => occurrenceFiltered.dispatches.map((dispatch) => {
          occurrenceDispatchesRelated.merge(JSON.parse(JSON.stringify([{
            agency: dispatch.agency,
            code: dispatch.code,
            dispatch_group: dispatch.dispatch_group,
            id: dispatch.id,
            status: dispatch.status,
            occurrenceId: dispatch.occurrenceId
          }])))
        }))
      syncOccurrenceDispatchesRelated.set(true)
    },
  })
}