import * as React from 'react';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { authFetch } from '~/services/fetch';
import { Downgraded, State, useHookstate } from '@hookstate/core';
import { FormControlLabel, Grid, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { InputSearchWithClear } from '~/components/InputSearchWithClear';
import FilterAutocompleteTypesAndSubtypesWithDescription, { type typesAndSubtypesSelectedProps } from '~/components/FilterAutocompleteTypesAndSubtypesWithDescription';

interface RenderTree {
  readonly id: string;
  readonly name: string;
  readonly type_id?: number | null;
  active?: boolean;
  readonly children?: readonly RenderTree[];
}

export interface typesFormatedForSendingProps {
  readonly type_id: number;
  readonly subtype_id?: number | null;
}

interface CrudCrudTreeFieldProps {
  readonly register?: UseFormRegister<any>;
  readonly model: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly options: {
    readonly firstListUrl: string,
    readonly secondListUrl: string,
    readonly nameChildren: string,
  };
  readonly placeholder?: string;
  readonly setFormValue: UseFormSetValue<any>;
}

// eslint-disable-next-line max-lines-per-function
export default function CrudTreeView({ register, model, getValues, options, placeholder, setFormValue }: CrudCrudTreeFieldProps) {
  const listData = useHookstate<RenderTree[]>([])
  const typeId = useHookstate<number | null>(null);
  const subtypeId = useHookstate<number[] | null>(null);
  const typesAndSubtypesSelected = useHookstate<typesAndSubtypesSelectedProps[] | []>([]);
  const typesFormatedForSending = useHookstate<typesFormatedForSendingProps[] | []>([]);

  const createObjectForSend = () => {
    typesFormatedForSending.set([])
    typesAndSubtypesSelected.get().map((type) => {
      if (type?.subtype?.length) {
        type.subtype.filter((sub) => sub?.typeId === type?.id).map((item) => {
          typesFormatedForSending.merge([{
            type_id: type?.id,
            subtype_id: item?.id ? item?.id : null
          }])
        })
      } else {
        typesFormatedForSending.merge([{
          type_id: type?.id,
        }])
      }
    })

    return typesFormatedForSending.get()
  }


  React.useEffect(() => {
        if (options?.secondListUrl != '') {
          authFetch({
            url: options?.secondListUrl,
            method: 'GET'
          }).then((response) => {
            const data = response.data
            data.map((item) => {
              typesAndSubtypesSelected.merge([{
                id: item.id,
                typeCode: item.code,
                typeName: item.name,
                subtype: item.subtypes?.map((sub) => {
                  return ({
                    id: sub.id,
                    subTypeName: sub.name,
                    subTypeCode: sub.code,
                    typeId: item.id
                  })
                })
              }])
            })
          })
        }
  }, [])


  React.useEffect(() => {
    setFormValue(model,
      createObjectForSend()
    )
  }, [typesAndSubtypesSelected.get()])


  return (
    <Grid container marginTop={2} marginLeft={2}>
      <FilterAutocompleteTypesAndSubtypesWithDescription
        typeId={typeId}
        subtypeIds={subtypeId}
        lockFields={false}
        typesAndSubtypesSelected={typesAndSubtypesSelected}
      />
    </Grid>

  );
}