import React from "react";
import {IconButton, Grid} from '@mui/material';
import {ArrowBackIos} from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';

interface PreviousNavegationButtonProps {
  readonly hasPrevious?: boolean;
  readonly previous?: VoidFunction;
  readonly loadingDispatchForm?: boolean;
}


export default function PreviousNavegationButton({ hasPrevious, previous, loadingDispatchForm }: PreviousNavegationButtonProps) {
  const { translate } = useLang()
 
  return (
    <Grid item>
      <IconButton title={translate('Previous')} disabled={!hasPrevious || loadingDispatchForm} onClick={() => previous ? previous() : false}>
        <ArrowBackIos/>
      </IconButton>
    </Grid>);

}